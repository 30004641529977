import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-percent-bar';
import { useUploadContext } from "../../context/Upload/Context"

const ProgressLoading = ({ fileWithMeta  }) => {
  return (
    <div style={{ width:'100%', maxWidth:'680px'}}>
      <div>
        <p style={{ fontSize: 'medium',
	fontWeight: '600',
	color: '#244F55' }}>{fileWithMeta?.meta?.name}</p>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', color: '#244F55' }}>
        <ProgressBar
          percent={fileWithMeta?.progress || 0}
          fillColor='#244F55'
          progress={fileWithMeta?.progress}
          width='90%'
          height='10px'
        />
        <p style={{ color: '#244F55' }}>{fileWithMeta?.progress || 0}%</p>
      </div>
    </div>
  );
};

export default ProgressLoading;

