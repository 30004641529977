import React, { useEffect, useRef, useState } from "react"
import Dropzone from "react-dropzone-uploader"
import styled from "styled-components"
import { CircularProgressbar } from "react-circular-progressbar"
import moment from "moment"
import "react-circular-progressbar/dist/styles.css"
import { DropzoneWrapper } from "../../styles/Dropzone"
import {
	getSignedUrl, getVideoCover,
	resetUploadedFilesListIntoStore,
	saveMergerVideosData,
	setErrorMessageIntoStore,
	setFilesIntoStore,
	setFilesUploadProgressIntoStore,
	setSubmissionFinishedIntoStore,
	setSuccessFiles,
	setUploadedFilesListIntoStore,
	setUploadStatusIntoStore,
	uploadFileToStorage
} from "../../services/UploadService"
import "react-dropzone-uploader/dist/styles.css"
import { uploadDispatch, useUploadContext } from "../../context/Upload/Context"
import Preview from "./Preview"
import MergeVideoPreview from "./MergeVideoPreview"
import { IconImages } from "../../styles/icons.js"
import { FlatButton, ResetButton, MergeVideos } from "../../styles/common.js"
import { useAuthContext } from "../../context/User/Context.js"
import { resetUploadedFilesList, setSubmissionFinished, setUploadedFilesList, SET_UPLOADED_FILES_LIST, setMeregeVideoStore, setMeregeVideoFiles, setUploadMeregeVideoList } from "../../context/Upload/Actions.js"
// import "firebase/database"
import firebase, { auth, storage } from '../../index';
import apiClient from "../../helpers/apiClient.js"
import ProgressLoading from "./ProgressLoading"
import { ColorRing } from 'react-loader-spinner'
import MergeVideoList from "./MergeVideoList"
import axios from "axios"
import { ClipLoader } from "react-spinners"
import { generateRandomDigit } from "../../helpers/someMethods"

const Upload = () => {
	const {
		uploadStatus,
		uploadedFilesList,
		selectedFiles,
		submissionFinished,
		errorMessage,
		filesUploadProgress,
		setMeregeVideo,
		mergeVideoStore,
		uploadMergeVideoList
	} = useUploadContext()
	const { user } = useAuthContext()

	const [canUpload, setCanUpload] = useState(true)
	const [uploading, setUploading] = useState(false)
	const [persistError, setPersistError] = useState(false)
	const [warning, setWarning] = useState(null)
	const [resetDropzone, setResetDropzone] = useState(0);
	const [uploadFileShow, setUploadFileShow] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [filteredVideoIds, setFilteredVideoIds] = useState([])
	const [showVideoMergeMsg, setShowVideoMergeMsg] = useState(false);
	const [showNewVideoButton, setShowNewVideoButton] = useState(false);
	const [loadingFiles, setloadingFiles] = useState(false)

	useEffect(() => {
		if (uploadedFilesList.length === selectedFiles.length && selectedFiles.length > 0) {
			setUploadStatusIntoStore("SUCCESS")
			setSuccessFiles([...selectedFiles])
			setSubmissionFinishedIntoStore(true)
			setCanUpload(true)
			setUploading(false)
			setUploadFileShow(true);
			for (const f of selectedFiles) {
				f.remove()
			}
			setFilesIntoStore([])
		}
	}, [uploadedFilesList, selectedFiles])

	useEffect(() => {
		if (!!errorMessage && !persistError) {
			setTimeout(() => {
				setErrorMessageIntoStore("")
			}, 4000)
		}
	}, [errorMessage])

	const bodyParameters = (selectedFile, meta, userId) => {
		const ext = selectedFile.name.substr(selectedFile.name.lastIndexOf("."))
		return {
			// filename: (selectedFile.lastModified || moment().valueOf().toString()) + "_" + userId + "_" + (selectedFile.lastModified || moment().valueOf().toString()) + "_" + `${meta.videoWidth || 200}x${meta.videoHeight || 200}_` + ext,
			filename: moment().valueOf().toString() + "_" + userId + "_" + moment().valueOf().toString() + generateRandomDigit() + "_" + `${meta.videoWidth || 200}x${meta.videoHeight || 200}_` + ext,
			type: selectedFile.type
		}
	}




	const handleChangeStatus = async (file, status, files) => {
		setloadingFiles(true)
		console.log({status, files});
		if (status === 'removed') return setloadingFiles(false)
		try {
			const GB_LIMIT = 5
			// setFilesIntoStore(files)
			try {
				const thumbBlob = await getVideoCover(file.file, 2)
				// console.log(thumbBlob)
			} catch (e) {
				if (status !== 'removed') {
					console.log('Failed', e)
					file.remove()
					return setErrorMessageIntoStore(
						"It looks like this file is invalid."
					)
				}
			}

			if (file.meta.videoHeight === 0 || file.meta.videoWidth === 0) {
				const sizing = 1
			}

			if (bytesToSize(file.meta.size) > GB_LIMIT && status === "done") {
				const filteredFiles = files.filter(o => bytesToSize(o.meta.size) <= GB_LIMIT)
				setFilesIntoStore([...filteredFiles, ...selectedFiles])
				file.remove()
				setErrorMessageIntoStore(
					"The file must be less than 5 GB."
				)
				return
			}

			const uniqueValues = new Set(
				[ ...selectedFiles.map( ( fileMeta ) => fileMeta.file.name ),
				...files.map( ( fileMeta ) => fileMeta.file.name )
				]
			)

			if (uniqueValues.size !== (selectedFiles.length + files?.length)) {
				const filteredFiles = files.filter(
					(v, i, a) => a.findIndex((t) => t.meta.name === v.meta.name) === i
				)
				setFilesIntoStore([...filteredFiles, ...selectedFiles])
				file.remove()
				setErrorMessageIntoStore(
					"Files with the same name cannot be uploaded. Duplicates were removed."
				)
			}

			let selectedVid = await files.map((data) => {
				const filenameData = bodyParameters(data.file, data.meta, user.id)
				const uid = filenameData.filename.split("_")[2]
				return {
					...data,
					filenameData,
					videoId: uid
				}
			})
			let tempfiles = [...selectedVid, ...selectedFiles]
			tempfiles = tempfiles.sort((a, b) => new Date(a.meta.lastModifiedDate) - new Date(b.meta.lastModifiedDate));
			setFilesIntoStore(tempfiles)
			const fiveMinutes = 1 * 60 * 1000; // 5 minutes in milliseconds
			const filteredVideoIds = [];


			for (let i = 0; i < tempfiles.length; i++) {
				const videoA = tempfiles[i];
				const lastModifiedDateA = new Date(videoA.meta.lastModifiedDate).getTime();
				const modifiedTimestampA = lastModifiedDateA + (videoA.meta.duration * 1000);

				for (let j = i + 1; j < tempfiles.length; j++) {
					const videoB = tempfiles[j];
					const lastModifiedDateB = new Date(videoB.meta.lastModifiedDate).getTime();
					const modifiedTimestampB = lastModifiedDateB + (videoB.meta.duration * 1000);

					const timeDifferenceAtoB = Math.abs(modifiedTimestampA - lastModifiedDateB);
					const timeDifferenceBtoA = Math.abs(modifiedTimestampB - lastModifiedDateA);

					if (timeDifferenceAtoB <= fiveMinutes) {
						if (!filteredVideoIds.includes(videoA?.filenameData?.filename)) {
							filteredVideoIds.push(videoA?.filenameData?.filename);
						}
						if (!filteredVideoIds.includes(videoB?.filenameData?.filename)) {
							filteredVideoIds.push(videoB?.filenameData?.filename);
						}
					}

					if (timeDifferenceBtoA <= fiveMinutes) {
						if (!filteredVideoIds.includes(videoA?.filenameData?.filename)) {
							filteredVideoIds.push(videoA?.filenameData?.filename);
						}
						if (!filteredVideoIds.includes(videoB?.filenameData?.filename)) {
							filteredVideoIds.push(videoB?.filenameData?.filename);
						}
					}
				}
			}

			setFilteredVideoIds(filteredVideoIds)

			// console.log({tempfiles});

			const filteredData = tempfiles.filter(item => filteredVideoIds.includes(item.filenameData?.filename) && !item?.isHide);

			filteredData.length ? setShowVideoMergeMsg(true) : setShowVideoMergeMsg(false);
			uploadDispatch(setMeregeVideoFiles((filteredData)))

		} catch (error) {
			console.log("handleChangeStatus error: " + error);
		} finally {
			setloadingFiles(false)
		}
	}

	const bytesToSize = (bytes) => {
		return bytes / 1024 / 1024 / 1024
	}

	const handleSubmitOld = async (filesWithMeta) => {
		// console.log({submissionFinished});
		// let uploadMergeVideoList = mergeVideoStore;
		if (submissionFinished) {
		setCanUpload(true);
		resetUploadedFilesListIntoStore();
		setFilesIntoStore([]);
		filesWithMeta.forEach((file) => file.remove());
		setSubmissionFinishedIntoStore(false);
		} else {
		// here we will set all merge videos in firstore -->>> mergeVideoStore

		try {
			let mergeVideoRes = null;
			if (mergeVideoStore?.length) {
			   mergeVideoRes = await saveMergerVideosData(mergeVideoStore);
			}


			await Promise.all(
			filesWithMeta.map((meta, i) => {
				return new Promise(async (resolve) => {
					const { isMergeFile, mainIndex, subIndex } = checkIsMergeFile(meta);
				setTimeout(async () => {
					try {
					// for merge file it should be upload on different folder
					// console.log({mergeVideoStore, meta});
					// uncompressedVideos


					console.log({ isMergeFile, mainIndex, subIndex });

					await getSignedUrl(
						meta.file,
						meta.meta,
						user.id,
						i,
						{
						...meta.filenameData,
						path: `content/${
							isMergeFile ? 'unmergedVideos' : 'uncompressedVideos'
						}/`,
						isCustomePath: true,
						},
						meta
					);
					if(isMergeFile) {
						mergeVideoStore[mainIndex].mergeVideos[subIndex] = {
							...mergeVideoStore[mainIndex]?.mergeVideos[subIndex],
							success: true,
							error:null,
						}
					}
					resolve();
					} catch (e) {
					if (e === 'USER_NOT_FOUND') {
						setPersistError(true);
						setErrorMessageIntoStore(
						'Please onboard in the mobile app before uploading a video.'
						);
					} else if (e === 'VERIFY_EMAIL') {
						setPersistError(true);
						setErrorMessageIntoStore('Please verify your email.');
					} else {
						console.log(e, '====================>>>>>>>>>>>>>>>');
						meta.meta.error = e || 'Failed to upload video';
						if(isMergeFile) {
							mergeVideoStore[mainIndex].mergeVideos[subIndex] = {
								...mergeVideoStore[mainIndex]?.mergeVideos[subIndex],
								success: false,
								error: e?.response?.data?.message || e?.response?.data || e || 'something went wrong',
							}
						}
						setWarning('Some files had issues being uploaded');
						setUploadedFilesListIntoStore({
						file: meta.file,
						meta: meta.meta,
						i,
						filenameData: meta.filenameData,
						isHide: false,
						});
						resolve(e);
					}
					}
				}, 1000);
				});
			})
			);
			setShowNewVideoButton(true);
			setUploadStatusIntoStore('SUCCESS');
			// set all merge video list here ;
			console.log({mergeVideoStore});
			uploadDispatch(setUploadMeregeVideoList(mergeVideoStore))
			setSubmissionFinished(true);
			setSubmissionFinishedIntoStore(true);
			setCanUpload(false);
			if (mergeVideoRes) {
				axios.post(`${process.env.REACT_APP_CLOUD_FUNCTION_URL}/mergeVideo`, {taskIds: mergeVideoRes?.data?.taskDataIds});
			}

		} catch (error) {
			console.error('Error during handleSubmit:', error);
		}
		}
  };

  const handleSubmit = async (filesWithMeta) => {
	// console.log({submissionFinished});
	// let uploadMergeVideoList = mergeVideoStore;
	if (submissionFinished) {
	setCanUpload(true);
	resetUploadedFilesListIntoStore();
	setFilesIntoStore([]);
	filesWithMeta.forEach((file) => file.remove());
	setSubmissionFinishedIntoStore(false);
	} else {
	// here we will set all merge videos in firstore -->>> mergeVideoStore
		
	try {
		let mergeVideoRes = null;
		if (mergeVideoStore?.length) {
		   mergeVideoRes = await saveMergerVideosData(mergeVideoStore);
		}

		await Promise.all(
			filesWithMeta.map((data,i)=>uploadFileToStorage(data, i , {selectedFiles, mergeVideoStore, setWarning: (e) =>setWarning(e)}))
		);

		setShowNewVideoButton(true);
		setUploadStatusIntoStore('SUCCESS');
		// set all merge video list here ;
		// console.log({mergeVideoStore});
		// uploadDispatch(setUploadMeregeVideoList(mergeVideoStore))
		setSubmissionFinished(true);
		setSubmissionFinishedIntoStore(true);
		setCanUpload(false);
		if (mergeVideoRes) {
			axios.post(`${process.env.REACT_APP_CLOUD_FUNCTION_URL}/mergeVideo`, {taskIds: mergeVideoRes?.data?.taskDataIds});
		}

	} catch (error) {
		console.error('Error during handleSubmit:', error);
	}
	}
};

	const reset = () => {
		resetUploadedFilesListIntoStore()
		setUploadStatusIntoStore("")
		setFilesIntoStore([])
		setSubmissionFinishedIntoStore(false)
		selectedFiles.forEach((file) => file.remove())
		setPersistError(false)
		setResetDropzone(resetDropzone + 1);
		uploadDispatch(setMeregeVideoStore([]))
		uploadDispatch(setMeregeVideoFiles([]))
		uploadDispatch(setUploadMeregeVideoList([]))
		setUploadFileShow(false);
		setShowNewVideoButton(false);
		setCanUpload(false);
	}

	const upload = async () => {
		setUploading(true)
		const userData = await apiClient
			.get("user")
		const isSubscribed = userData.data.isSubscribe ? userData.data.isSubscribe : false;
		if (isSubscribed) {
			if (uploadedFilesList.length) {
				reset()
			} else {
				setUploading(true)
				handleSubmit(selectedFiles)
			}
		} else {
			setPersistError(true)
			setErrorMessageIntoStore("Video can not be uploaded as you do not have an active subscription. Please subscribe to gain access.")
			reset()
			setUploading(false)
		}

	}

	const merge = async () => {
		mergeVideoStore.push({operationDate: setMeregeVideo[0]?.meta?.lastModifiedDate, mergeVideos: setMeregeVideo})
		uploadDispatch(setMeregeVideoStore(mergeVideoStore))

		await Promise.all(selectedFiles.map((item2) => {
			const matchedItem = setMeregeVideo.find((item1) => item1?.meta?.id === item2?.meta?.id);
			if (matchedItem) {
				item2.isHide = true;
			}
		}));
		console.log({selectedFiles});
		setFilesIntoStore(selectedFiles)
		uploadDispatch(setMeregeVideoFiles([]))
	}

	// useEffect(() => {
	// 	// console.log(docId);
	// 	const unsubscribe = db.collection('mergeVideoTask').doc(docId)
	// 		.onSnapshot((doc) => {
	// 			if (doc.exists) {
	// 				const newData = doc.data();
	// 				if (newData?.status == 'success') {
	// 					uploadMergeVideoList.push(newData?.videoData[0]);
	// 					setUploadMeregeVideoList(uploadMergeVideoList);
	// 					uploadDispatch(setMeregeVideoStore([]))
	// 					setUploadFileShow(true);
	// 					setMergeProgress(false);
	// 				}
	// 				else if (newData?.status == 'failed'){
	// 					setPersistError(true)
	// 					setErrorMessageIntoStore("Please try again")
	// 					reset()
	// 					setUploading(false)
	// 					setMergeProgress(false)
	// 				}
	// 			} else {
	// 				return
	// 			}
	// 		});

	// 	return () => unsubscribe();
	// }, [docId]);

	/* const totalFiles = filesUploadProgress.length
	let totalPercent = totalFiles > 0 ? Math.round(filesUploadProgress.map(o => o.progress).reduce((total, progress) => (total + progress), 0) / totalFiles) : 0; */
	const disabled = uploadStatus === "SUCCESS" || uploading

	const inputContent = () => {
		return uploadStatus === "SUCCESS" ?
			<DropContent>
				<IconImages type={!!warning ? "error" : "success"} />
				<div className="success-text">{!!warning ? "Oops" : "Success"}!</div>
				<div className="drop-text">
					{warning || "You will be notified once the uploaded files are ready for editing"}
				</div>
			</DropContent>
			: !!errorMessage ?
				<DropContent>
					<IconImages type={"error"} />
					<div className="success-text">Oops! Something went wrong</div>
					<div className="drop-text">
						{errorMessage}
					</div>
				</DropContent> :
				// uploading ? <>
				// 	<UploadList>
				// 		{/* {selectedFiles.map((fileWithMeta, i) => <Preview fileWithMeta={fileWithMeta} meta={fileWithMeta.meta}
				// 				index={i} />)} */}

				// 	</UploadList>
				// </>

				// 	:
					 <DropContent style={{ width: '100%' }}>
						<IconImages style={{ marginLeft: 'auto', marginTop: '-30px', marginRight: '5px' }} type={"about"} onMouseEnter={() => setShowMessage(true)}
							onMouseLeave={() => setShowMessage(false)} />
						{showMessage && <div className="hover-message">Supported File :.MP4, .MOV, .MPEG4, .OGG, .MKV, <br /> .WebM, .F4V, .M4V, .Qt, .3G2</div>}
						<IconImages type={"dropImage"} />
						<div className="drop-text">
							Drag & drop file here <br />
							or Click to upload
						</div>
					</DropContent>
	}

	const uploadVideosButton = (title, style) => (
		<>
			{!uploading && <div style={style}>
				<FlatButton disabled={uploadedFilesList.length === 0 && (selectedFiles.length === 0/*  || !canUpload */)}
					onClick={upload}>
					{title}
				</FlatButton>
			</div>}
		</>
	)

	/* {!uploading && <UploadList>
		{selectedFiles.map((fileWithMeta, i) => <Preview fileWithMeta={fileWithMeta} meta={fileWithMeta.meta}
																										 index={i}/>)}
	</UploadList>} */


	return loadingFiles ? (
    <DropzoneWrapper>
      {
			loadingFiles && <ClipLoader color={'black'} loading={true} size={150}/>
		}
    </DropzoneWrapper>
  ) : (
    <DropzoneWrapper>

      {!uploading && !loadingFiles && (
        <UploadList>
          {/* all selected files  */}
          {selectedFiles.map((fileWithMeta, i) => (
            <Preview
			  key={i + fileWithMeta?.meta?.name}
              date
              fileWithMeta={fileWithMeta}
              meta={fileWithMeta.meta}
              index={i}
              checkBoxStatus={selectedFiles.length > 1}
              isHide={fileWithMeta?.isHide}
              filteredVideoIds={filteredVideoIds}
            />
          ))}

          {/* // merge vdeos groups files  */}
          {selectedFiles.length != 0
            ? mergeVideoStore.map(({ mergeVideos, operationDate }, i) => (
                <MergeVideoList
                  key={i + operationDate || 2232}
                  date
                  fileWithMeta={mergeVideos}
                  operationDate={operationDate}
                  index={i}
                />
              ))
            : null}

          {/* info text  */}
          {setMeregeVideo.length && showVideoMergeMsg && !uploadFileShow ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '13px',
                  color: '#f79b04',
                  margin: '0 auto',
                  padding: '0px 20%',
                }}
              >
                Based on the metadata fetched from the videos it seems like the
                selected videos are sourced from the same video file. If you
                would like to merge these videos click on the{' '}
                <b>"MERGE VIDEOS"</b>.
              </p>
            </div>
          ) : null}

          {/* merge video button  */}
          {selectedFiles.length > 1 && (
            <div style={{ width: '80%', display: 'flex' }}>
              <MergeVideos disabled={setMeregeVideo.length < 2} onClick={merge}>
                Merge Videos
              </MergeVideos>
            </div>
          )}
        </UploadList>
      )}

      {showNewVideoButton ? (
        <div className={'top-upload-btn'}>
          <ResetButton onClick={reset}>New Upload</ResetButton>
        </div>
      ) : null}
      {!loadingFiles && !uploading && <Dropzone
        key={resetDropzone}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        disabled={disabled}
        styles={{
          preview: { display: 'none' },
        }}
        inputContent={inputContent()}
        inputWithFilesContent={inputContent()}
        accept='video/*, .ogg'
        // validate={validateFileType}
        // validator={validateFileType}
      />}
				{ uploading && <UploadList style={ { display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30, padding: '0 20px' } }>
					{ selectedFiles.map( ( fileWithMeta, i ) => <ProgressLoading key={ i + fileWithMeta?.videoId } fileWithMeta={ fileWithMeta }
						index={ i } /> ) }
				</UploadList> }

      {uploadedFilesList.length === 0 && !loadingFiles
        ? uploadVideosButton('Upload Videos', { marginTop: 34 })
        : null}

      {uploadedFilesList.length > 0 && uploadFileShow && (
        <UploadList style={{ marginTop: 30 }}>
          {uploadedFilesList.map((fileWithMeta, i) => (
            <Preview
			  key={i + fileWithMeta?.meta?.name}
              fileWithMeta={fileWithMeta}
              meta={fileWithMeta.meta}
              index={i}
              isHide={fileWithMeta?.isHide}
            />
          ))}
         </UploadList>
      )}

      {uploadMergeVideoList.length > 0 && uploadFileShow &&  (
        <UploadList>
          {uploadMergeVideoList.map(({mergeVideos, operationDate}, i) => (
            <MergeVideoPreview
			  key={i + operationDate}
              mergeVideos={mergeVideos}
              index={i}
            />
          ))}
        </UploadList>
      )}
    </DropzoneWrapper>
  );
}

export default Upload

const DropContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.drop-text {
		text-align: center;
		margin-top: 14px;
		font-family: Inter, sans-serif;
		font-size: 18px;
		color: #333333;
		line-height: 26px;
		font-weight: 400;
	}

	.success-text {
		font-family: Inter, sans-serif;
		font-size: 24px;
		color: white;
		margin-top: 12px;
		line-height: 26px;
		font-weight: 400;
	}

	.hover-message {
		font-family: Inter, sans-serif;
		font-size: 12px;
		color: white;
		line-height: 15px;
		font-weight: 400;
		display: flex;
		margin-top: -92px;
		align-items: center;
		position: absolute;
		border-radius:12px !important;
		right: 22px;
		color: #fff;
		padding: 8px 16px;
		border-radius: 4px;
		opacity: 0.9;
		z-index: 9999;
		background: #0F5158;
		box-shadow: 0px 10px 25px rgba(37, 37, 37, 0.05);
		border-radius: 8px;
	}
`

const UploadList = styled.div`
	width: 100%;
	/* overflow-y: scroll;
	padding: 10px 0;
	margin-top: 10px */
`

const RotatingBar = styled(CircularProgressbar)`
	-webkit-animation: spin 1s linear infinite;
	-moz-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
	@-moz-keyframes spin {
		100% {
			-moz-transform: rotate(360deg);
		}
	}
	@-webkit-keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
`
