import {uploadDispatch} from "./Context.js";

export const SET_FILES = 'SET_FILES';
export const SET_FILES_UPLOAD_PROGRESS = 'SET_FILES_UPLOAD_PROGRESS';
export const SET_UPLOAD_STATUS = 'SET_UPLOAD_STATUS';
export const SET_IS_FILE_UPLOADING = 'SET_IS_FILE_UPLOADING';
export const SET_UPLOADED_FILES_LIST = 'SET_UPLOADED_FILES_LIST';
export const RESET_UPLOADED_FILES_LIST = 'RESET_UPLOADED_FILES_LIST';
export const SET_SUBMISSION_FINISHED = 'SET_SUBMISSION_FINISHED';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_UPLOAD_FILES = 'SET_UPLOAD_FILES'
export const PATCH_UPLOADED_FILES = 'PATCH_UPLOADED_FILES'
export const PATCH_SELECTED_FILES = 'PATCH_SELECTED_FILES'
export const PATCH_MERGE_FILES = 'PATCH_MERGE_FILES'
export const SET_MERGE_VIDEO = 'SET_MERGE_VIDEO'
export const MERGE_VIDEOS_STORE = 'MERGE_VIDEOS_STORE'
export const UPLOAD_MERGE_VIDEOS_LIST = 'UPLOAD_MERGE_VIDEOS_LIST'

export function setFiles(selectedFiles) {
  return {
    type: SET_FILES,
    payload: selectedFiles,
  };
}

export function setFilesUploadProgress(
  filesUploadProgress
) {
  return {
    type: SET_FILES_UPLOAD_PROGRESS,
    payload: filesUploadProgress,
  };
}

export function setUploadStatus(uploadStatus) {
  return {
    type: SET_UPLOAD_STATUS,
    payload: uploadStatus,
  };
}

export function setUploadedFiles(files) {
  return {
    type: SET_UPLOAD_FILES,
    payload: files
  }
}

export function setIsFileUploading(isFileUploading) {
  return {
    type: SET_IS_FILE_UPLOADING,
    payload: isFileUploading,
  };
}

export function setUploadedFilesList(uploadedFile) {
  return {
    type: SET_UPLOADED_FILES_LIST,
    payload: uploadedFile,
  };
}

export function resetUploadedFilesList() {
  return {
    type: RESET_UPLOADED_FILES_LIST,
    payload: [],
  };
}

export function setPending(
	status
) {
	return {
		type: SET_STATUS,
		payload: submissionFinished,
	};
}

export function setSubmissionFinished(
  submissionFinished
) {
  return {
    type: SET_SUBMISSION_FINISHED,
    payload: submissionFinished,
  };
}

export function setErrorMessage(errorMessage) {
  return {
    type: SET_ERROR_MESSAGE,
    payload: errorMessage,
  };
}

export function patchUploadedFiles(index, data) {
  return uploadDispatch({
    type: PATCH_UPLOADED_FILES,
    payload: {
      index,
      data: {
        ...data
      }
    },
  });
}

export function patchSelectedFiles(index, data) {
  return uploadDispatch({
    type: PATCH_SELECTED_FILES,
    payload: {
      index,
      data
    },
  });
}

export function patchMergeFiles(index, data) {
  return uploadDispatch({
    type: PATCH_MERGE_FILES,
    payload: {
      index,
      data
    },
  });
}

export function setMeregeVideoFiles(videosData) {
  return {
    type: SET_MERGE_VIDEO,
    payload: videosData,
  };
}

export function setMeregeVideoStore(videosData) {
  return {
    type: MERGE_VIDEOS_STORE,
    payload: videosData,
  };
}

export function setUploadMeregeVideoList(videosData) {
  return {
    type: UPLOAD_MERGE_VIDEOS_LIST,
    payload: videosData,
  };
}