import React from "react"
import { BigContainer, BigLetter, Container, SmallTitle, Title } from "../styles/terms.js"

const Terms = ({}) => {


	return (
		<Container>
			<Title>
				Terms of Use
			</Title>

			<p style={{ marginBottom: 0 }}>
				<BigContainer>
					<BigLetter>S</BigLetter>
					urgiyo LLC (together with any affiliates, “Surgiyo”) provides a multi-platform service for surgeons and other healthcare
				</BigContainer>
			</p>
			<p style={{ marginTop: 0 }}>
				professionals to upload, host and edit surgery videos in the cloud. These Terms of Use (“Terms”), which include
				our Privacy Policy, govern your use of this service through a browser, mobile app and any related online or
				offline services that we provide (collectively, the “Services”). To the extent you have entered into a
				contractual agreement executed by Surgiyo, the terms of that agreement will control in the event of any
				conflict.
			</p>

			<p>
				Surgiyo requires that all users of the Services adhere to these Terms, including with respect to any electronic
				content, functionality, features and applications in our Services (collectively, “Materials”). If you are an
				individual, by using the Services or registering an account with us, you affirm that you are of legal age to
				form a binding contract with us and agree to our Terms. If you are acting or registering an account on behalf an
				organization, you further represent that you are authorized to, and do, accept these Terms on behalf of the
				organization. References to “you” in these Terms or Privacy Policy mean both you in a personal capacity and, if
				applicable, the organization you represent.
			</p>

			<p>
				Your right to access or use the Services can be terminated if you violate these Terms or as otherwise provided
				in these Terms. We reserve the right to revise these Terms, and any rights not expressly granted herein are
				reserved. Please check back periodically to review any changes to our Terms. Please see Sections 10-12 below
				regarding your legal rights in any dispute involving our Services.
			</p>

			<p>
				THE SERVICES ARE GENERALLY INTENDED FOR INFORMATIONAL PURPOSES ONLY. THE SERVICES DO NOT, IN ANY WAY, OFFER ANY
				DIAGNOSIS OR MEDICAL ADVICE.
			</p>

			<SmallTitle>
				Your Responsibilities
			</SmallTitle>

			<p>
				You are responsible for any activity that occurs through your user account and you agree you will not sell,
				transfer, license or assign your account, username, or any account rights. With the exception of individuals
				authorized to create accounts on behalf of an organization, we prohibit the creation of and you agree that you
				will not create an account for anyone other than yourself. All information you provide or provided to us upon
				registration and at all other times must be true, accurate, current and complete and you agree to update your
				information as necessary to maintain its truth and accuracy. You agree that we are not responsible or liable for
				the conduct of any other user.
			</p>


			<SmallTitle>
				User Conduct and Restrictions
			</SmallTitle>

			<p>
				You agree not to: (a) decompile, reverse engineer, disassemble, modify, reduce the Services to human readable
				form or create derivative works based upon the Services or any part thereof; (b) disable any licensing or
				control features of the Services; (c) introduce into the Services any virus or other code or routine intended to
				disrupt or damage the Services, or alter, damage or delete any Materials , or retrieve or record information
				about the Services or its users; (d) merge the Services or Materials with another program or create derivative
				works based on the Services or Materials; (e) remove, obscure, or alter any notice of the copyright or other
				proprietary legends on the Services or Materials; (f) sublicense, assign, translate, rent, lease, lend, resell
				for profit, distribute or otherwise assign or transfer the Materials or access to the Services to others; (g)
				use, or allow the use of, the Services or the Materials in contravention of any federal, state, local, foreign
				or other applicable law, or rules or regulations of regulatory or administrative organizations; (h) otherwise
				act in a fraudulent, illegal, malicious or negligent manner when using the Services.
				Except as expressly provided herein, we and our third party licensors reserve all rights with respect to the
				Services, and may pursue all legally available options under both civil and criminal laws (and may cooperate
				with law enforcement agencies) in the event of any violations; (i) post violent, defamatory, indecent, sexually
				explicit, discriminatory, unlawful, infringing, hateful or other inappropriate photos or other content,
				including any posts intended for defaming, stalking, bullying, abusing, harassing, threatening, impersonating,
				harming, impersonating or intimidating people or entities; or (j) create, solicit, transmit, or procure the
				sending of, any unwanted, unsolicited or harassing comments or communications, including advertising or
				promotional material, without our prior written consent, including any “junk mail,” “chain letter,” “spam” or
				any other similar solicitation. You may not access or use the Services by means of any automated program, expert
				system, electronic agent or “bot,” and shall not give any other person or entity unauthorized access to the
				Services. You are prohibited from “scraping,” copying, republishing, licensing, or selling the data or
				information on the Services if you do so for commercial purposes.
			</p>

			<SmallTitle>
				Payments and Billing
			</SmallTitle>

			<p>
				If you elect to use fee-based portions of the Services, you may be required to pay a fee (“Fee”) on a one-time
				or subscription basis (“Subscription”). All amounts are in U.S. Dollars. We will notify you via email in advance
				of subscription renewals, and you can access your payment history in your Account or by contacting us. We may
				revise the Fees for our Services at any time, or impose additional fees or charges, in which case we will notify
				you by posting the change to our Services or by email. Fee changes will be effective as of the next billing
				cycle. If you opt-in to a Subscription or other Fee-based Services, you (i) agree to pay us the applicable Fees
				in accordance with these Terms, (ii) authorize us, through our payment processor, to charge you via your
				provided payment method for the Fees, and (iii) agree to such payment(s). All Fees are exclusive of taxes,
				duties, levies, tariffs, and other governmental charges (including, without limitation, VAT) other than taxes
				based on our income (collectively, “Taxes”). You will be responsible for paying all Taxes. All paid Services are
				offered on demand starting from the date of purchase (or as soon as the Fee is processed thereafter) for a
				specific timeframe. The timeframe for any purchased Services shall expire 3 years from purchase unless a shorter
				timeframe is specified.
				All payments are subject to the terms of our third-party payment processor in addition to these Terms. We are
				not responsible for any payment processor errors or mistakes, but will reasonably attempt to address such issues
				if they arise. If we, through the payment processor, do not receive payment from you, you agree to pay all Fees
				upon request.
				Subscription Fees are automatically charged on a recurring basis (i.e., auto-renewal). Unless you cancel or
				change your Subscription(s) in accordance with these Terms, your Subscription(s) will be automatically extended
				for successive renewal periods of the same duration as the Subscription(s) originally selected, at the
				then-posted Fees. YOU ACKNOWLEDGE AND AGREE THAT WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) FOR ANY
				SUBSCRIPTION YOU OPT INTO WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL WE RECEIVE PRIOR NOTICE THAT YOU HAVE
				TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES
				SUBMITTED BEFORE WE CAN REASONABLY ACT. YOU CAN TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD
				THROUGH THE SERVICES OR BY CONTACTING US. This does not waive our right to seek payment directly from you.
				You must provide current, complete, and accurate information for your account, including billing details such as
				billing address, credit card number and credit card expiration date. You must promptly notify us if your payment
				method is canceled (e.g., for loss or theft) or if you become aware of a security issue.
				Once initiated by you, payment obligations are non-refundable. Except as expressly set forth in these Terms or
				otherwise agreed-upon with us in writing, we do not issue refunds of any Fees for early cancellation or
				termination of the Services, or for any other reason. To the extent any return is required by applicable law for
				Fees paid, it will be provided in credits for paid portions of the Services. If you have a question or issue
				with the charges applicable to your account, please contact us immediately. You agree to notify us of any Fee
				dispute within thirty (30) days of it being due, and must cooperate with us in good faith to promptly resolve
				any such dispute. If the charges were made in error, we will credit your account or payment method for the
				appropriate amount. You agree to reimburse us for all reasonable costs and expenses incurred in collecting
				delinquent amounts.
			</p>


			<SmallTitle>
				User Content
			</SmallTitle>
			<p>
				Exercise caution, good sense, and sound judgment in using the Services. You are responsible for any material and
				information that you provide to us through the Services, including information, pictures, video and other
				materials that you submit (“User Content”).
			</p>

			<p>
				You hereby grant us a non-exclusive, fully paid and royalty-free, transferable, sub-licensable, worldwide
				license to use and display the information and materials that you provide to us through the Services as
				reasonably necessary to provide the Services to you, subject to our Privacy Policy. You represent and warrant
				that: (i) all of your User Content complies with these Terms; (ii) you own or control all necessary rights and
				consents to the User Content provided by you through the Services; (iii) you will pay for all royalties, fees,
				and any other monies owed in connection with User Content you provided through the Services; (iv) you understand
				and acknowledge that you are responsible for any User Content that you submit or contribute, and you, not us,
				have full responsibility for it, including its legality, reliability, accuracy, and appropriateness; (v) our
				receipt and use of the User Content as provided herein will not violate any privacy laws; and (vi) we are not
				responsible or liable to any third party for the privacy, content or accuracy of any User Content you submit to
				the Services. We will not be liable to you or third-party beneficiaries for any modification, suspension, or
				discontinuation of the Services, or the loss of any User Content.
			</p>

			<p>
				You agree that we are free to use any feedback, ideas or suggestions (“Feedback”) that you provide to us with
				respect to the Services for any purposes whatsoever without any restriction, including developing and marketing
				new products, services and features without any liability or payment of any kind to you. You waive all
				intellectual property rights in any such Feedback.
			</p>

			<SmallTitle>
				Privacy and HIPAA
			</SmallTitle>
			<p>
				Personal information collected by us in connection with the Services will be maintained in accordance with our
				posted Privacy Policy, which is incorporated by reference as if fully set forth herein and forms a material part
				of these Terms.
				With respect to your User Content:
			</p>

			<ul>
				<li>
					Where required by applicable laws, you (or your organization) must execute and provide back to us the Surgiyo
					Business Associate Agreement (“BAA”);
				</li>
				<li>
					Each party hereto shall, at all times, comply with its obligations under all applicable federal and state data
					privacy and security laws, including as applicable the Health Insurance Portability and Accountability Act and
					associated laws and regulations, as amended (collectively, “HIPAA”), with respect to all personal information
					and protected health information (each, as defined by applicable laws) that is collected, used, disclosed or
					otherwise processed pursuant to the Services, as set forth in the BAA; and
				</li>
				<li>
					To the extent required by such laws, you are solely responsible for any required patient notices and consents,
					and you are solely responsible for any disclosures of your User Content to third parties by Surgiyo when
					directed by you through the Services.
				</li>
			</ul>

			<SmallTitle>
				Reporting Infringement And Other Violations
			</SmallTitle>
			<p>
				We respect the intellectual property rights of others, and we prohibit users of our Services from submitting,
				uploading, posting or otherwise transmitting any materials that violate another person’s intellectual property
				rights or these Terms. For allegations of copyright infringement, please follow the instructions below;
				otherwise, you can report any issues to us via the contact information provided at the end of these Terms. The
				remaining information in this section is provided exclusively for notifying us that your copyrighted material
				may have been infringed.
				Pursuant to 17 U.S.C. 512(c)(2), written notification must be submitted in writing to the Designated Agent as
				follows:
				Attn: Surgiyo DMCA Administrator
				Subject: Notification of Claimed Infringement
				By mail to: Surgiyo, 1758  Locksley Dr., Lathrop, CA 95330
				Or via email to: <a href="mailto:stanley.park.245@gmail.com">stanley.park.245@gmail.com</a>
				To be effective, the notification of copyright infringement must include the following: (1) A physical or
				electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is
				allegedly infringed; (2) Identification of the copyrighted work claimed to have been infringed, or if multiple
				copyrighted works at a single online site are covered by a single notification, a representative list of such
				works at that site; (3) Identification of the material that is claimed to be infringing or to be the subject of
				infringing activity and that is to be removed or access to which is to be disabled, and information reasonably
				sufficient to permit the service provider to locate the material; (4) Information reasonably sufficient to
				permit the service provider to contact the complaining party, such as an address, telephone number, and if
				available, an electronic mail address at which the complaining party may be contacted; (5) A statement that the
				complaining party has a good faith belief that use of the material in the manner complained of is not authorized
				by the copyright owner, its agent, or the law; and (6) A statement that the information in the notification is
				accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner
				of an exclusive right that is allegedly infringed.
			</p>

			<SmallTitle>
				Our Intellectual Property Rights
			</SmallTitle>
			<p>
				We grant you a limited, non-exclusive, non-sublicensable, non-transferable, revocable license to access and use
				the Services for lawful purposes in accordance with these Terms (and if applicable, the governing terms of any
				business relationship you have with us). The Services contain Materials owned by (or licensed to) us, including
				name, logo, text, images, audio/visual works, icons and scripts and other materials provided on or through the
				Services. Except as provided herein or with our express prior written permission, none of the information and
				Materials provided by the Services may be copied, displayed, distributed, downloaded, licensed, modified,
				published, re-posted, reproduced, reused, sold, transmitted, used to create a derivative work or otherwise used
				for public or commercial purposes. Trademarks and service marks that may be referred to in the Services are the
				property of Surgiyo or their respective owners. Nothing in the Services should be construed as granting, by
				implication, estoppel, or otherwise, any license or right to use any trademark without our written permission.
			</p>

			<SmallTitle>
				Links to other sites
			</SmallTitle>
			<p>
				The Services may contain links to third party sites. Access to any other website or service referenced in the
				Services is at the user’s own risk and we are not responsible for the accuracy or reliability of any
				information, data, opinions, advice or statements made on other sites. We provide these links merely as a
				convenience and the inclusion of such links does not imply an endorsement, recommendation or approval. The
				content, accuracy, opinions expressed and other links provided by linked sites are not investigated, verified,
				monitored or endorsed by us. If you decide to visit, or transact business at any linked site, you do so at your
				own risk and it is your responsibility to take all protective measures. The third party sites are not controlled
				by us, and may have different terms of use and privacy policies, which we encourage you to review.
			</p>

			<SmallTitle>
				Third-Party links
			</SmallTitle>
			<p>
				The Service may include links to third-party websites, plug-ins and applications. Clicking on those links or
				enabling those connections may allow third parties to collect or share data about you. We do not control these
				third-party websites and are not responsible for their privacy statements. When you leave our app, we encourage
				you to read the privacy policy of every website you visit.
				We may maintain a presence on social media platforms such as LinkedIn, Facebook and Twitter, (collectively,
				“Social Media Platforms”) to provide a place for people to learn more about Surgiyo and to share experiences
				with our Services. All comments, visuals, and other materials posted by visitors to our Social Media Platforms
				do not necessarily reflect the opinions or ideas of Surgiyo. All use of the Social Media Platforms must comply
				with the respective Social Media Platform’s terms of use. We may review some but not all postings to our
				accounts on Social Media Platforms, and we may remove postings in our discretion, such as when we determine they
				are inappropriate or offensive.
			</p>

			<SmallTitle>
				Disclaimer of Warranties; Limitation of Liability
			</SmallTitle>
			<p>
				YOUR USE OF THE SERVICES AND MATERIALS IS AT YOUR OWN RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
				THE SERVICES AND MATERIALS ARE PROVIDED “AS IS” WITHOUT A REPRESENTATION OR WARRANTY OF ANY KIND, EITHER
				EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
				PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE FUNCTIONS OR CONTENT CONTAINED ON THE
				SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT OUR SERVERS ARE FREE OF
				VIRUSES OR OTHER HARMFUL COMPONENTS THROUGH USE OR DOWNLOADING MATERIAL FROM THE SERVICES. UNDER NO
				CIRCUMSTANCES SHALL WE BE LIABLE FOR ANY DIRECT OR INDIRECT, ACTUAL, SPECIAL, PUNITIVE, INCIDENTAL OR
				CONSEQUENTIAL DAMAGES THAT MAY ARISE FROM THE SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
				LIMITATION OF LIABILITY FOR CERTAIN DAMAGES, AND IN SUCH JURISDICTIONS OUR LIABILITY IS LIMITED TO THE GREATEST
				EXTENT PERMITTED BY LAW.
			</p>

			<p>
				BY ACCESSING THE SERVICES, YOU UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT ARE AT THIS
				TIME UNKNOWN OR UNSUSPECTED, AND IN ACCORDANCE WITH SUCH WAIVER, YOU ACKNOWLEDGE THAT YOU HAVE READ AND
				UNDERSTAND, AND HEREBY EXPRESSLY WAIVE, THE BENEFITS OF SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, AND ANY
				SIMILAR LAW OF ANY STATE OR TERRITORY, WHICH PROVIDES AS FOLLOWS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS
				WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE,
				WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
			</p>

			<p>
				WE DO NOT ENDORSE, APPROVE, OR VERIFY ANY POSTED USER CONTENT ON THE SERVICES AND WE SHALL NOT BE RESPONSIBLE OR
				LIABLE WHATSOEVER IN ANY MANNER FOR ANY USER CONTENT POSTED ON THE SERVICES, INCLUDING CLAIMS OF INFRINGEMENT
				RELATING TO USER CONTENT POSTED ON THE SERVICES, FOR YOUR USE OF THE SERVICES, OR FOR THE CONDUCT OF THIRD
				PARTIES WHETHER ON THE SERVICES OR RELATING TO THE SERVICES.
			</p>

			<p>
				We may update our Services and Materials from time to time, but they will not necessarily be complete or
				up-to-date. Although it is our intention for the Services to be available as much as possible, there may be
				occasions when the Services may be interrupted, including, without limitation, for scheduled maintenance or
				upgrades, for emergency repairs, or due to failure of telecommunications links and/or equipment. We may remove
				any content from the Services for any reason, without prior notice. User Content removed from the Services may
				continue to be stored by us , including, without limitation, in order to comply with certain legal obligations,
				but may not be retrievable without a valid court order or similar legal process. If you are a consumer, the
				provisions in these Terms are intended to be only as broad and inclusive as is permitted by the laws of your
				state of residence. We reserve all rights, defenses and permissible limitations under the law of your state of
				residence.
			</p>

			<SmallTitle>
				Indemnification
			</SmallTitle>
			<p>
				You agree to indemnify us and hold us harmless from and against any and all loss, expenses, damages, and costs,
				including without limitation reasonable attorneys’ fees, resulting, whether directly or indirectly, from your
				violation of these Terms. You also agree to indemnify us and hold us harmless from and against any and all
				claims brought by third parties arising out of your use of the Services and the content you submit to the
				Services by any means, including without limitation through a posting, a link, reference to other content, or
				otherwise.
			</p>

			<SmallTitle>
				Governing Law; Arbitration and Class Action Waiver
			</SmallTitle>
			<p>
				THE LAWS OF THE STATE OF CALIFORNIA WILL GOVERN THESE TERMS AND ANY DISPUTE RELATING TO THE SERVICES, WITHOUT
				GIVING EFFECT TO ANY PRINCIPLES OF CONFLICTS OF LAWS. EXCLUDING DISPUTES ADDRESSED THROUGH ARBITRATION AS
				PROVIDED BELOW, YOU AGREE TO SUBMIT TO THE EXCLUSIVE JURISDICTION OF THE COMPETENT STATE AND FEDERAL COURTS
				LOCATED IN SAN JOSE, CALIFORNIA IN RELATION TO ANY CLAIM, DISPUTE OR DIFFERENCE ARISING FROM THESE TERMS, AND
				YOU AGREE TO WAIVE ANY RIGHT OF REMOVAL OR TRANSFER WHETHER DUE TO FORUM NON CONVENIENS OR OTHER REASON.
			</p>

			<p>
				Excluding claims for injunctive or other equitable relief, for claims related to the Services, any dispute or
				controversy arising out of or relating to these Terms, including without limitation, any and all disputes,
				claims (whether in tort, contract, statutory or otherwise) or disagreements concerning the existence, breach,
				interpretation, application or termination of the Terms, shall be resolved by final and binding arbitration in
				accordance with the JAMS Inc. Comprehensive Arbitration Rules & Procedures then in effect. There shall be no
				right or authority for any claims to be arbitrated on a class action basis. The arbitration shall take place in
				San Jose, California or at the option of the party seeking relief, online, by telephone, online, via written
				submissions alone, or the complainant’s state of residence, and be administered by JAMS. The decision of the
				arbitrator will be final and binding on the parties. Judgment on any award(s) rendered by the arbitrator may be
				entered in any court having jurisdiction thereof. Nothing in this section shall prevent either party from
				seeking immediate injunctive relief from any court of competent jurisdiction, and any such request shall not be
				deemed incompatible with the agreement to arbitrate or a waiver of the right to arbitrate. The parties undertake
				to keep confidential all awards in their arbitration, together with all confidential information, all materials
				in the proceedings created for the purpose of the arbitration and all other documents produced by the other
				party in the proceedings and not otherwise in the public domain, save and to the extent that disclosure may be
				required of a party by legal duty, to protect or pursue a legal right or to enforce or challenge an award in
				legal proceedings before a court or other judicial authority.
			</p>

			<p>
				WHERE PERMITTED UNDER THE APPLICABLE LAW, YOU AGREE THAT EACH PARTY TO A DISPUTE HEREUNDER MAY BRING CLAIMS
				AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
				PURPORTED CLASS OR REPRESENTATIVE ACTION. Unless both you and we agree, no arbitrator or judge may consolidate
				more than one person’s claims or otherwise preside over any form of a representative or class proceeding.
			</p>

			<p>
				The Services are controlled and operated from within the United States. We make no representation that the
				Services are appropriate or available for use in all jurisdictions. Access to any of the Services from
				jurisdictions where such access is illegal is strictly prohibited. If you choose to access the Services from
				such jurisdictions, you do so at your own risk. You are always responsible for your compliance with applicable
				laws.
			</p>

			<SmallTitle>
				Termination of Services.
			</SmallTitle>
			<p>
				We may, in our sole discretion and at any time, change or discontinue providing any part of the Services. We may impose or change fees for use of certain Services with fifteen (15) days’ advance notice of such change (or such longer notice as may be required by applicable law).
			</p>

			<SmallTitle>
				Entire Agreement; Severability
			</SmallTitle>
			<p>
				Except to the extent you are using the Services on behalf of an organization that has entered into a contractual relationship with us for these Services and the terms of that contractual relationship conflict with or otherwise supersede these Terms, you agree that (i) you have read and understood and agree to be bound by these Terms, (ii) these Terms, together with our posted Privacy Policy which is incorporated herein by reference, constitute the complete and exclusive statement of the agreement between you and Surgiyo, and supersedes all other proposals or prior agreements oral or written, and any other communications relating to the subject matter of these Terms.
			</p>
			<p>
				If any provision of these Terms is found unenforceable, it shall not affect the validity of the remainder of these Terms, which shall remain valid and enforceable according to its terms, and the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect. You agree that no joint venture, partnership, employment, or agency relationship exists between you and Surgiyo as a result of these Terms or your use of the Services.
			</p>

			<SmallTitle>
				Contact Information
			</SmallTitle>
			<p>
				If you have any questions, concerns or comments about these Terms or our Services, please contact us at <a href="mailto:contact@surgiyo.com">contact@surgiyo.com.</a> 
				These Terms were last updated December 14, 2021.
			</p>

		</Container>
	)
}

export default Terms

