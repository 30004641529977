import React, {useContext, useEffect} from 'react'
import styled from 'styled-components';
import {UserContext, userDispatch} from "../context/User/Context.js";
import {setDialogueMessage} from "../context/User/Actions.js";
import {IconImages} from "../styles/icons.js";

const Dialogue = ({}) => {
  const {state} = useContext(UserContext);
  const {dialogueMessage} = state;

  const dismiss = () => {
    const action = setDialogueMessage('')
    userDispatch(action);
  }

  return (
    <DialogueBox visible={!!dialogueMessage}>
      <div className={'inner-container'}>
        <IconImages type={'error'}/>
        <div>
          <h3>Oops</h3>
          <div>{dialogueMessage}</div>
        </div>
      </div>
      <div className={'button-container'} onClick={dismiss}>
        <button>Dismiss</button>
      </div>
    </DialogueBox>
  );
};

export default Dialogue;

const DialogueBox = styled.div`
  width: 98%;
  max-width: 600px;
  height: 190px;
  border-radius: 0 0 12px 12px;
  background-color: black;
  box-shadow: 1px 20px 24px rgba(255, 255, 255, 0.1);
  border: thin solid grey;

  border-top-width: 0;

  position: absolute;
  z-index: 9999999999;
  left: 0;
  right: 0;
  margin: auto;

  ${props => props.visible ? `
    transform: translateY(0px);
      transition-duration: 0.5s;
    ` : `
      transform: translateY(-240px);
      transition-duration: 0.5s;
    `}
  .inner-container {
    padding-left: 10%;
    padding-top: 30px;
    display: flex;
    align-items: center;

    img {
      position: relative;
      top: 12px;
      margin-right: 30px;
    }
  }

  .button-container {
    padding-right: 10%;
    padding-top: 30px;
    text-align: right;

    button {
      height: 30px;
      width: 100px;
      border-radius: 6px;
      background-color: #244F55;
      border: none;
      color: white;
      cursor: pointer;
    }
  }

`
