import styled from "styled-components";

export const SmallTitle = styled.div`
  margin-top: 23px;
  font-family: FletchaLM, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 27px;
  color: #093034;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
`

export const BigContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Container = styled.div`
  padding: 40px;
  color: white;
  max-width: 500px;
  margin: 12px auto;
  
  a {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: rgba(9, 48, 52, 0.6);
    @media (prefers-color-scheme: dark) {
      color: rgba(255, 255, 255, 0.6);
    }
  }
  
  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: rgba(9, 48, 52, 0.6);
    @media (prefers-color-scheme: dark) {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  ul {
    li {
      &::marker {
        color: #0F5158;
        @media (prefers-color-scheme: dark) {
          color: white;
        }
      }

      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      color: rgba(9, 48, 52, 0.6);

      @media (prefers-color-scheme: dark) {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
`

export const BigLetter = styled.span`
  font-family: FletchaLM, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 85px;
  line-height: 85px;
  color: #0F5158;
  padding-right: 20px;
  @media (prefers-color-scheme: dark) {
    color: #3EC9B1;
  }
`

export const Title = styled.div`
  font-family: FletchaMM, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 46px;
  line-height: 85px;
  text-transform: capitalize;
  color: #093034;
  text-align: left;
  @media (prefers-color-scheme: dark) {
    color: white
  }
`;
