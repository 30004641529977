import React from "react"
import { BigContainer, BigLetter, Container, SmallTitle, Title } from "../styles/terms.js"

const Privacy = ({}) => {

	const section = () => (
		<>
			<SmallTitle>
				How We Share Your Information
			</SmallTitle>

			<p>
				We will not disclose your personal information to third parties without your consent, except as permitted by law
				in the following circumstances.
			</p>

			<ul>
				<li>

				</li>
				<li>

				</li>
				<li>

				</li>
				<li>

				</li>
			</ul>
		</>
	)

	return (
		<Container>
			<Title>
				Privacy Policy
			</Title>

			<p style={{ marginBottom: 0 }}>
				<BigContainer>
					<BigLetter>S</BigLetter>
					urgiyo LLC (together with any affiliates, “Surgiyo”) provides a multi-platform service for surgeons and other
				</BigContainer>
			</p>
			<p style={{ marginTop: 0 }}>
				healthcare professionals to upload, host and edit surgery videos in the cloud. This Privacy Policy describes how
				Surgiyo collects, uses, or discloses personal information we receive through a browser, mobile app and any
				related online or offline services that we provide (collectively, the “Services”). This policy also describes
				your choices about the collection and use of your information. To the extent you have entered into a contractual
				agreement executed by Surgiyo, the terms of that agreement will control in the event of any conflict.
				Please read the Privacy Policy carefully before you start to use our Services. By using the Services, you agree
				to be bound and abide by our posted Terms of Use (“Terms”), which incorporate this Privacy Policy by reference.
				If you do not agree to our Terms and this Privacy Policy, or if you violate them in any way, your right to
				access or use the Services is terminated. Please see Sections 10-12 of our Terms regarding your legal rights in
				any dispute involving our Services.
			</p>

			<p>
				NOTE: AS PROVIDED IN OUR TERMS, YOU (OR YOUR ORGANIZATION) MUST ENTER INTO A BUSINESS ASSOCIATE AGREEMENT
				(“BAA”) WITH SURGIYO BEFORE PROVIDING ANY USER CONTENT SUBJECT TO THE HEALTH INSURANCE PORTABILITY AND
				ACCOUNTABILITY ACT AND ASSOCIATED LAWS AND REGULATIONS, AS AMENDED (COLLECTIVELY, “HIPAA”). THE BAA SUPERSEDES
				THIS PRIVACY POLICY WITH RESPECT TO USER CONTENT
			</p>

			<SmallTitle>
				How We Collect Information
			</SmallTitle>

			<p>
				We may collect information about you by various means, including:
			</p>

			<ul>
				<li>Information you provide to us directly; and</li>
				<li>Through your use of our Services;</li>
				<li>From other sources and social media platforms that you may use to engage with us.</li>
			</ul>

			<SmallTitle>
				Types of Information We Collect
			</SmallTitle>

			<p>
				In order to better provide you with our Services, we may collect the following categories of information:
			</p>

			<ul>
				<li>Profile and subscription information, such as your name, mailing address, e-mail address, phone number,
					usage and subscription preferences, professional title and role, user credentials for any access-restricted
					Services, and payment information if you pay any fees or subscriptions;
				</li>
				<li>Organizational information, such as information provided by an organization that your account or profile is
					associated with;
				</li>
				<li>Survey information in response to questions that we may ask you through the Services, including for research
					purposes or to improve our Services;
				</li>
				<li>User Content that you may provide to us via the Services (e.g., information, photos or videos you may post
					to the Services, social media content, testimonials, and other materials) along with any associated
					information that you provide about yourself or the submitted content;
				</li>
				<li>Communications between you and us, such as via e-mail, web form, phone or other channels;</li>
				<li>Offline activity that you engage in with us, if any; and</li>
				<li>Online User Activity described in the next section.</li>
			</ul>

			<p>
				If you provide us with information regarding another individual, you represent that you have that person’s
				authorization and consent to give us his or her information and to permit us to use the information in
				accordance with this policy.
			</p>

			<SmallTitle>
				Online User Activity, Cookies and Information Collected by Other Automated Means
			</SmallTitle>

			<p>
				Cookies are a commonly-used web technology that allow websites or mobile apps to store and retrieve certain
				information on a user’s system, and track users’ online activities. We and our service providers may collect
				information about your use of our Services by such automated means, including but not limited to cookies,
				pixels, SDKs and other similar technologies.
				Cookies and similar technologies can help us automatically identify you when you return to our website or app.
				Cookies help us review traffic patterns, improve our Services, and determine what Services are popular.
			</p>

			<SmallTitle>
				When you use the Services, the information we may collect by automated means includes, for example:
			</SmallTitle>

			<ul>
				<li>Usage Details about your interaction with our Services (such as the date, time, and length of visits, and
					specific pages or content accessed during the visits, search terms, frequency of the visits, referring website
					addresses);
				</li>
				<li>Device Information including the IP address and other details of a device that you use to connect with our
					Services (such as device type and unique device identifier, operating system, browser type, mobile network
					information, and screen size); and
				</li>
				<li>Location information where you choose to provide us with information about your device’s location.</li>
			</ul>

			<p>
				If a user does not want information collected through the use of cookies, most browsers allow the visitor to
				reject cookies, but if you choose to decline cookies, you may not be able to fully experience the interactive
				features our Services provide. There is no consensus on web browser-based do-not-track (“DNT”) mechanisms, but
				we do not respond to web browser-based DNT signals at this time because we do not collect information about
				users’ online activities across third-party websites.
			</p>

			<SmallTitle>
				How We Use Information We Collect
			</SmallTitle>

			<p>
				We may use the information we obtain about you for purposes allowed by applicable laws, including:
			</p>

			<ul>
				<li>Provide our Services to you (or your organization, as applicable), including to establish and maintain any
					account that is created for your use of our Services;
				</li>
				<li>Respond to your requests, questions and comments and provide customer support;</li>
				<li>Operate, evaluate and improve the products and services we offer (including to develop new services), and to
					diagnose or fix technology problems;
				</li>
				<li>Tailor the content that we display to you in our Services and communications, including advertising, and
					offer opportunities and information that we believe may be of interest to you;
				</li>
				<li>Monitor the performance of our Services including metrics such as total number of visitors, traffic, and
					demographic patterns, as well as analyze and enhance our communications and strategies (including by
					identifying when emails sent to you have been received and read);
				</li>
				<li>Provide you with administrative notices, such as to inform you about changes to this Privacy Policy and our
					Terms; and
				</li>
				<li>Comply with and enforce as needed applicable legal requirements, industry standards, our policies and our
					contractual rights.
				</li>
			</ul>

			<p>
				We may also use or share information in an anonymized or aggregate manner for purposes such as research,
				analysis, modeling, marketing, and advertising, as well as improvement of our Services
			</p>

			<SmallTitle>
				How We Share Your Information
			</SmallTitle>

			<p>
				We will not disclose your personal information to third parties without your consent, except as permitted by law
				in the following circumstances.
			</p>

			<ul>
				<li>
					We may share your information with the organization you are associated with if your account is managed or paid
					for by them;
				</li>
				<li>
					We may share your information with service providers that we believe need the information to perform a
					technology, business, or other professional function for us (examples include IT services, maintenance and
					hosting of our Services, and other vendors)—we only provide such vendors with information so they can perform
					their required functions on our behalf;
				</li>
				<li>
					We also may disclose information about you (i) if we are required to do so by law or legal process, (ii) when
					we believe disclosure is necessary to prevent harm or financial loss, (iii) in connection with an
					investigation of suspected or actual fraudulent or illegal activity; or (iv) under exigent circumstances to
					protect the personal safety of our staff, users or the public; and
				</li>
				<li>
					We may transfer the information we maintain in connection with the sale or transfer of all or the relevant
					portion of our organization or assets—if we engage in such a sale or transfer, we will make reasonable efforts
					to direct the recipient to use your personal information in a manner that is consistent with this Privacy
					Policy.
				</li>
			</ul>

			<p>
				Where appropriate, we will limit sharing of your information in accordance with the choices you have provided us
				and applicable law.
			</p>

			<SmallTitle>
				Your Privacy Choices
			</SmallTitle>

			<p>
				We offer you certain choices about what information we collect from you, how we use and disclose the
				information, and how we communicate with you.
			</p>

			<ul>
				<li>
					Account management: You may access and modify your account details and User Content through the Services. When
					the Services are being provided under a relationship with an organization, we will act as directed by the
					organization. We may retain any account data for internal purposes or as otherwise provided in this Privacy
					Policy and our Terms of Use.
				</li>
				<li>
					Informational Emails: From time to time, we may contact you via email or through other channels for the
					purpose of providing announcements, opportunities to participate in our programs or research, or other general
					communications. In order to improve our Services, we may be notified when you open an email from us or click
					on a link therein. You may choose not to receive certain informational emails from us by clicking on the
					unsubscribe link in the emails you receive from us. Even if you opt out of receiving such communications, we
					may continue sending you non-informational email communications, such as administrative or transaction alerts,
					or information about changes to our terms of service.
				</li>
				<li>
					Cookies: Web browsers may offer users the ability to disable receiving certain types of cookies; however, if
					cookies are disabled, some features or functionality of our websites and mobile apps may not function
					correctly.
				</li>
			</ul>

			<SmallTitle>
				Links to Other Websites and Third-Party Content
			</SmallTitle>

			<p>
				Our Services may contain links to other websites or apps. Please be aware that we are not responsible for the
				content or privacy practices of such other websites or apps, and we encourage you to be aware when you leave our
				Services and to read the privacy statements of any other website or app that collects personal information.
			</p>

			<SmallTitle>
				Online Advertising
			</SmallTitle>

			<p>
				You may see us promoted by other organizations, on various websites, web pages, social media and other
				platforms. Please note that we do not always have complete information about where our organization may be
				displayed or promoted, and if you believe that we are featured in venues that are inappropriate or offensive,
				please contact us.
			</p>

			<p>
				We may serve advertisements, and allow third parties to serve advertisements, through our Services.
				Advertisements served through our Services may be targeted to users who fit a certain general profile category,
				which may be inferred from information that you provide to us or, based on your website usage patterns, or based
				on your activity on other websites or online services as may be available to the ad networks.
			</p>


			<SmallTitle>
				How We Protect Information
			</SmallTitle>

			<p>
				We endeavor to maintain reasonable administrative, technical and physical safeguards designed to protect the
				personal information we maintain against accidental, unlawful or unauthorized destruction, loss, alteration,
				access, disclosure or use. However, we cannot ensure the security of any information you transmit to us, or
				guarantee that this information will not be accessed, disclosed, altered, or destroyed. We will make any legally
				required disclosures in the event of any compromise of personal information. To the extent the law allows us to
				provide such notification via e-mail or conspicuous posting on the Services, you agree to accept notice in that
				form.
			</p>

			<SmallTitle>
				Children
			</SmallTitle>

			<p>
			The Services are only intended and permitted for use by persons over the age of 18 who are able to be contractually bound to our Terms under applicable law. We do not seek to collect information from children under the age of 18 and will endeavor to promptly delete such information if discovered.

			</p>

			<SmallTitle>
				International Data Transfers and Use
			</SmallTitle>

			<p>
				Our Services provided in the United States are not intended to subject us to the laws or jurisdiction of any
				state, country or territory other than those of the United States, as provided in our Terms.
			</p>

			<SmallTitle>
				Updates to Our Privacy Policy
			</SmallTitle>

			<p>
				We may revise or update this Privacy Policy at any time. We will post the updated version on our Services, and
				may notify you of any significant changes to our Privacy Policy. Your continued use of our Services after such
				updates will constitute an acknowledgment of the change and agreement to abide and be bound by the updated
				Privacy Policy.
			</p>
			
			<SmallTitle >
				<a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" target="_blank" style={{fontFamily: 'FletchaLM, sans-serif', fontStyle: 'normal', fontWeight: 'normal', fontSize:'21px', lineHeight: '27px',color:'#093034'}}>Licensed Application End User LicenseAgreement</a>
			</SmallTitle>

			<SmallTitle>
				Contacting Us
			</SmallTitle>

			<p>
				If you have any questions, concerns or comments about this Privacy Policy, our privacy practices, or if you
				would like us to update information or preferences you provided to us, please contact us
				at <a href="mailto:contact@surgiyo.com">contact@surgiyo.com.</a>.
				This Privacy Policy was last updated December 14 2021.
			</p>

		</Container>
	)
}

export default Privacy
