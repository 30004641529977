import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useUploadContext} from '../../context/Upload/Context';
import {IconImages} from "../../styles/icons.js";
import VideoPlayer from "simple-react-video-thumbnail-j";
import DateUpdater from "../DateUpdater.js";
import moment from 'moment'
import {patchUploadedFiles} from "../../context/Upload/Actions.js";
import {usePatchVideo} from "../../context/hooks/upload.hook.js";


const FileDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 67px;
  min-height: 67px;
  font-size: 14px;
  /* width: 90%; */
  /* margin-bottom: 16px; */
  /* box-shadow: 0 0 10px #cdcdcd; */
  .video-player {
    margin-left: 22px;
  }

  .video-player, video, .video-player__thumbnail-container img, .video-player__thumbnail-container {
    height: 30px;
    width: 30px;
    border-radius: 7px;

    img {
      object-fit: cover;
    }
  }

  .video-player__icon-container {
    display: none;
  }
`;

const MainDiv = styled.div`
  /* border: thin solid #a6a6a6; */
  border-radius: 10px;
  width: 90%;
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0px 4px 24px -4px rgba(227,224,227,1);
  -moz-box-shadow: 0px 4px 24px -4px rgba(227,224,227,1);
  box-shadow: 0px 4px 24px -4px rgba(227,224,227,1);
  @media (prefers-color-scheme: dark) {
    -webkit-box-shadow: 0px 0px 7px 2px rgba(66,63,66,1);
    -moz-box-shadow: 0px 0px 7px 2px rgba(66,63,66,1);
    box-shadow: 0px 0px 7px 2px rgba(66,63,66,1);
  }
  margin-bottom: 16px;
  padding: 10px 0;
  /* margin-top: 10px; */
`

const FileName = styled.span`
  overflow-wrap: break-word;
  margin-left: 22px;
`;

const MergeVideoPreview = ({mergeVideos, index}) => {
    /* const [fileData, setFileData] = useState({
      localUrl: '',
      thumb: ''
    }) */
    // const {name} = meta;
    const {filesUploadProgress, submissionFinished} = useUploadContext();

    const renderFile = (fileWithMeta, index) => {
      const [fileData, setFileData] = useState({
      localUrl: '',
      thumb: ''
    })
    const videoPlayerRef = useCallback(ref => {}, [])
    useEffect(() => {
      if (fileWithMeta) {
        const u = (window.URL || window.webkitURL);
        if (u) {
          setFileData({
            thumb: '',
            localUrl: u.createObjectURL(fileWithMeta.file)
          })
        }
      }
    }, [fileWithMeta])

      return (
        <FileDetails key={index+fileWithMeta?.meta?.name}>
          {fileData.localUrl && <VideoPlayer
          videoUrl={fileData.localUrl}
          snapshotAt={3}
          ref={videoPlayerRef}
        />}
          <FileName style={{overflowWrap:'anywhere',width:'65%'}}>{fileWithMeta?.meta?.name}</FileName>
          {fileWithMeta.error ?
            <Failure>
              File upload failed
              <IconImages type={'error'} style={{width: 28}}/>
            </Failure>
            : null
          }
          {/* {!submissionFinished && (
            <IconImages className="trash" onClick={remove} type={'trash'}/>
          )} */}
        </FileDetails>
      );
    }

    return (
      <MainDiv style={{flexDirection:'column'}}>
        {
          mergeVideos?.map((data, i)=> (
            renderFile(data, i)
          ))
        }
      </MainDiv>
    );
  }
;
export default MergeVideoPreview;

const Failure = styled.div`
  background-color: rgba(255, 255, 255, 0.08);
  max-width: 165px;
  width: 150px;
  height: 37px;
  border-radius: 8px;
  margin-left: auto;
  margin-right: 20px;
  color: #B6B6B6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 10px;
  font-family: Inter, sans-serif;
`
