import styled from 'styled-components';

export const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90%;
  /* max-height: 90vh; */
  padding: 4rem 0;
  /* height: 90vh; */
  /* height: calc(96vh - 100px); */
  /* margin-top: 100px; */
  /* overflow-y: scroll; */

  button:focus {
    outline: none;
  }

  .top-upload-btn {
    width: 90%;
    max-width: 680px;
    text-align: right;
  }

  .CircularProgressbar {
    width: 40%;

    .CircularProgressbar-trail {
      stroke: #0a0a0a;
    }
    .CircularProgressbar-path {
      stroke: #3EC9B1;
    }

    .CircularProgressbar-text {
      transform: translate(2px, 3px);
      font-family: FletchaMM, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 26px;
      text-align: center;
      fill: #244F55 !important;
    }
  }

  .dzu-dropzoneDisabled {
    opacity: 1 !important;
  }

  .dzu-dropzoneActive {
   border: thin dashed #3EC9B1 !important;
    background-color: transparent !important;
  }

  .dzu-inputLabelWithFiles {
  }

  .dzu-dropzone {
    margin-top: 18px;
    border: 1px dashed #333333;
    padding: 10px 10px 10px 10px;
    align-items: flex-start;
    min-height: 200px;
    width: 90%;
    max-width: 680px;
  }
  .dzu-inputLabel {
    font-weight: 500;
    font-size: 16px;
  }

  .dzu-submitButtonContainer {
    display: none;
  }

  .dzu-inputLabelWithFiles {
    padding: 0;
    margin: 0;
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #244F55;
  }
`;
