import React from "react"
import styled from "styled-components"
import moment from "moment"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ClipLoader } from "react-spinners"



const DateUpdater = ({ date, loading, updateDate }) => {

	return (
		<div style={{marginLeft:'auto'}}>
		<h5 style={{margin:0, marginLeft:10, marginBottom:5}}>Surgery date</h5>	
        <DateField>
			{!loading ? moment(date).format("MM/DD/yyyy") : <ClipLoader size={16} color={"white"}/>}
			<DatePicker
				maxDate={new Date()}
				disabled={loading}
				onChange={updateDate}
				selected={moment(date).toDate()}
				calendarContainer={FakeDate}
			/>
		</DateField>
		</div>
		
	)
}

export default DateUpdater

const FakeDate = styled.div`
`


const DateField = styled.div`
	input, .react-datepicker-wrapper {
		max-width: 100%;
	}

	.react-datepicker {
		left: -80px;
	}

	.react-datepicker-wrapper {
		width: 0;
		max-width: 100%;
		position: relative;
		left: -80px;

		input {
			cursor: pointer;
			opacity: 0;
			max-width: 90px;
		}
	}
	border: solid 1px #3EC9B1;
	min-width: 92px;
	height: 25px;
	background-color: #FFFFFF;
	color: black !important;
	border-radius: 10px;
	margin-left: auto;
	margin-right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-family: Inter, sans-serif;
	font-size: 12px;
	text-transform: capitalize;
	line-height: 16px;
	letter-spacing: 0.01em;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`
