import axios from "axios"
import moment from "moment"
import apiClient from "../helpers/apiClient.js"
import {
	resetUploadedFilesList,
	setErrorMessage,
	setFiles,
	setFilesUploadProgress,
	setIsFileUploading,
	setSubmissionFinished,
	setUploadedFiles,
	setUploadedFilesList,
	setUploadMeregeVideoList,
	setUploadStatus
} from "../context/Upload/Actions.js"
import { uploadDispatch, useUploadContext } from "../context/Upload/Context.js"
import "firebase/auth"
import { setDialogueMessage } from "../context/User/Actions.js"
import { userDispatch } from "../context/User/Context.js"
import firebase from "firebase"
import 'firebase/app'
import "firebase/database"
import { storage } from "../index.js"
let timeout

export function setFilesIntoStore(files) {
	try {
		const action = setFiles(files)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setFilesUploadProgressIntoStore(
	filesUploadProgress
) {
	try {
		const action = setFilesUploadProgress(filesUploadProgress)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setUploadStatusIntoStore(uploadStatus) {
	try {
		const action = setUploadStatus(uploadStatus)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setSuccessFiles(files) {
	try {
		const action = setUploadedFiles(files)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setIsFileUploadingIntoStore(isFileUploading) {
	try {
		const action = setIsFileUploading(isFileUploading)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setUploadedFilesListIntoStore(uploadedFile) {
	try {
		const action = setUploadedFilesList(uploadedFile)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function resetUploadedFilesListIntoStore() {
	try {
		const action = resetUploadedFilesList()
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setSubmissionFinishedIntoStore(
	submissionFinished
) {
	try {
		const action = setSubmissionFinished(submissionFinished)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

export function setErrorMessageIntoStore(errorMessage) {
	try {
		const action = setErrorMessage(errorMessage)
		uploadDispatch(action)
	} catch (error) {
		console.log(error)
	}
}

const bodyParameters = (selectedFile, meta, userId) => {
	const ext = selectedFile.name.substr(selectedFile.name.lastIndexOf("."))
	const id = moment().valueOf().toString()
	return {
		filename: (selectedFile.lastModified || moment().valueOf().toString()) + "_" + userId + "_" + id + "_" + `${meta.videoWidth || 200}x${meta.videoHeight || 200}_` + ext,
		type: selectedFile.type
	}
}

export const getSignedUrl = async (selectedFile, meta, userId, index, filenameData, metaData) => {
	setIsFileUploadingIntoStore(true);
	try {
		const response = await apiClient.post("storage/get_signed_url", filenameData);
		await uploadFile(response.data.url, selectedFile, filenameData.filename, meta, metaData);
		if (meta.videoWidth !== 0 && meta.videoHeight !== 0) {
			// console.log(response.data.thumbUrl, 'response.data.thumbUrl =======>>>>>>', filenameData.filename);
			await uploadThumbnail(response.data.thumbUrl, selectedFile, filenameData.filename);
		}
	} catch (error) {
		throw error;
	}
};
const progressJson = []
export const uploadFile = async (signedUrl, selectedFile, filename, meta, metaData) => {
	return new Promise(async (resolve, reject) => {
		const blob = selectedFile.slice(0, selectedFile.size, selectedFile.type);
		const newFile = new File([blob], filename, { type: selectedFile.type });
		const config = {
			headers: { "Content-Type": newFile.type },
			onUploadProgress: (progressEvent) => {
				const progress = progressEvent.loaded;
				const percent = Math.round((progress * 100) / progressEvent.total);
				const fileProgress = { file: filename, progress: percent };
				const existingFile = progressJson.find(item => item.file === fileProgress.file);

				if (existingFile) {
					// If the file already exists, update its progress
					existingFile.progress = fileProgress.progress;
				} else {
					// If the file doesn't exist, add it to progressJson
					progressJson.push(fileProgress);
				}
				setFilesUploadProgressIntoStore(progressJson);
			},
		};

		try {
			const res = await axios.put(signedUrl, newFile, config);
			const uid = newFile.name.split("_")[2];
			setUploadedFilesListIntoStore({ file: newFile, meta, uid, isHide: metaData?.isHide  }); //  || metaData?.isHide
			// if video in merge then show in merge video
			// if () {
				// setMeregeVideo
			// }
			// setSubmissionFinished(true);
			// setUploadStatusIntoStore("SUCCESS");
			console.log(uid);
			resolve()
		} catch (error) {
			console.error("Error during uploadFile:", error);
			setUploadStatusIntoStore("Something went wrong. Please try again.");
			reject()
		}
	})

};

export const uploadThumbnailOld = async (signedUrl, selectedFile, filename) => {
	try {
		const thumbBlob = await getVideoCover(selectedFile, 2);
		const newFile = new File([thumbBlob], filename, { type: "image/jpeg" });

		await axios.put(signedUrl, newFile, {
			headers: { "Content-Type": "image/jpeg" },
		});
	} catch (error) {
		console.error("Error during uploadThumbnail:", error);
		const action = setDialogueMessage("There was an error uploading your thumbnail.");
		userDispatch(action);
	}
};

export const uploadThumbnail = async (selectedFile, filename) => {
	try {
		const thumbBlob = await getVideoCover(selectedFile.file, 2);
		const storageRef = storage.ref()
		return await storageRef.child( '/content/thumbs/' + filename ).put( thumbBlob );
	} catch (error) {
		console.error("Error during uploadThumbnail:", error);
		const action = setDialogueMessage("There was an error uploading your thumbnail.");
		userDispatch(action);
	}
};


export function getVideoCover(file, seekTo = 0.0) {
	return new Promise((resolve, reject) => {
		// load the file to a video player
		const videoPlayer = document.createElement("video")
		videoPlayer.setAttribute("src", URL.createObjectURL(file))
		videoPlayer.load()
		videoPlayer.addEventListener("error", (ex) => {
			console.log("Failed video loading", ex)
			reject("error when loading video file", ex)
		})
		// load metadata of the video to get video duration and dimensions
		videoPlayer.addEventListener("loadedmetadata", () => {
			// seek to user defined timestamp (in seconds) if possible
			if (videoPlayer.duration < seekTo) {
				reject("video is too short.")
				return
			}
			// delay seeking or else 'seeked' event won't fire on Safari
			setTimeout(() => {
				videoPlayer.currentTime = seekTo
			}, 200)
			// extract video thumbnail once seeking is complete
			videoPlayer.addEventListener("seeked", () => {
				// console.log("video is now paused at %ss.", seekTo)
				// define a canvas to have the same dimension as the video
				const canvas = document.createElement("canvas")
				canvas.width = videoPlayer.videoWidth || 400
				canvas.height = videoPlayer.videoHeight || 300
				// draw the video frame to canvas
				const ctx = canvas.getContext("2d")
				ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
				// return the canvas image as a blob
				ctx.canvas.toBlob(
					blob => {
						resolve(blob)
					},
					"image/jpeg",
					0.75 /* quality */
				)
			})
		})
	})
}


export const saveMergerVideosData = async (mergeVideos) => {
	try {
		return await apiClient.post("storage/save_merge_video_data", mergeVideos, {
			headers: { "Content-Type": "application/json" },
		});
	} catch (error) {
		console.error("Error during saveMergerVideosData:", error);
		throw error
	}
};


export const uploadFileToStorage = async ( fileData, i, { selectedFiles, mergeVideoStore, setWarning } ) =>
{
	return new Promise( async ( resolve ) =>
	{
		const { isMergeFile, mainIndex, subIndex } = checkIsMergeFile( fileData, mergeVideoStore );
		// setTimeout(async () => {
		try
		{
			// Upload file and metadata to the object 'images/mountains.jpg'
			const storageRef = storage.ref()
			var uploadTask = storageRef.child( `content/${ isMergeFile ? 'unmergedVideos' : 'uncompressedVideos'
				}/` + fileData.filenameData?.filename ).put( fileData.file, { resumable: true } );

			// Listen for state changes, errors, and completion of the upload.
			uploadTask.on( firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
				( snapshot ) => trackFileUpload( snapshot, fileData, selectedFiles ),
				handleFileUploadError,
				async () =>
				{
					// Upload completed successfully, now we can get the download URL
					uploadTask.snapshot.ref.getDownloadURL().then( async ( downloadURL ) =>
					{
						const uid = fileData?.filenameData?.filename.split( "_" )[ 2 ];

						setUploadedFilesListIntoStore( { file: fileData.file, meta: fileData.meta, uid, isHide: fileData?.isHide } );
						console.log( 'File available at', downloadURL );
						if ( isMergeFile ) {
							mergeVideoStore[ mainIndex ].mergeVideos[ subIndex ] = {
								...mergeVideoStore[ mainIndex ]?.mergeVideos[ subIndex ],
								success: true,
								error: null,
							}
							uploadDispatch( setUploadMeregeVideoList( mergeVideoStore ) )
						}
						await uploadThumbnail( fileData, fileData?.filenameData?.filename )
						return resolve();
					} );
				}
			);

		} catch ( e )
		{
			if ( e === 'USER_NOT_FOUND' )
			{
				setPersistError( true );
				setErrorMessageIntoStore(
					'Please onboard in the mobile app before uploading a video.'
				);
			} else if ( e === 'VERIFY_EMAIL' )
			{
				setPersistError( true );
				setErrorMessageIntoStore( 'Please verify your email.' );
			} else
			{
				console.log( e, '====================>>>>>>>>>>>>>>>' );
				fileData.meta.error = e || 'Failed to upload video';
				if ( isMergeFile )
				{
					mergeVideoStore[ mainIndex ].mergeVideos[ subIndex ] = {
						...mergeVideoStore[ mainIndex ]?.mergeVideos[ subIndex ],
						success: false,
						error: e?.response?.data?.message || e?.response?.data || e || 'something went wrong',
					}
					uploadDispatch( setUploadMeregeVideoList( mergeVideoStore ) )
				}
				setWarning( 'Some files had issues being uploaded' );
				setUploadedFilesListIntoStore( {
					file: fileData.file,
					meta: fileData.meta,
					i,
					filenameData: fileData.filenameData,
					isHide: false,
				} );
				resolve( e );
			}
		}
	} );
}

const trackFileUpload = ( snapshot, fileData, selectedFiles ) =>
{
	// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
	let progress = Math.round( ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100 );
	const newModified = selectedFiles.find( ( { filenameData } ) => filenameData.filename === fileData?.filenameData?.filename )

	newModified.status = 'Running';
	newModified.progress = progress;
	setFilesIntoStore( selectedFiles )

	console.log( 'Upload is ' + progress + '% done' );
	switch ( snapshot.state )
	{
		case firebase.storage.TaskState.PAUSED: // or 'paused'
			console.log( 'Upload is paused' );
			break;
		case firebase.storage.TaskState.RUNNING: // or 'running'
			console.log( 'Upload is running' );
			break;
	}

}

const handleFileUploadError = ( error ) =>
{
	// A full list of error codes is available at
	// https://firebase.google.com/docs/storage/web/handle-errors
	switch ( error.code )
	{
		case 'storage/unauthorized':
			// User doesn't have permission to access the object
			break;
		case 'storage/canceled':
			// User canceled the upload
			break;
		case 'storage/unknown':
			// Unknown error occurred, inspect error.serverResponse
			break;
	}
}

const checkIsMergeFile = (meta, mergeVideoStore) => {
	let isMergeFile = null,
		mainIndex,
		subIndex;
	isMergeFile = mergeVideoStore?.length
					? mergeVideoStore?.some(({ mergeVideos }, i) =>
						mergeVideos.some(
						({ filenameData }, j) =>{
						 if(filenameData?.filename === meta?.filenameData?.filename) {
							mainIndex = i;
							subIndex= j;
							return true
						 } else {
							return false
						 }

						})
					)
					: null;

	return {
		isMergeFile,
		mainIndex,
		subIndex,
	}
}