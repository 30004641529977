import React, { useState } from 'react'
import styled from 'styled-components';
import DateUpdater from '../DateUpdater';
import moment from 'moment';
import { patchMergeFiles, setMeregeVideoFiles, setMeregeVideoStore } from '../../context/Upload/Actions';
import {TfiSplitV} from 'react-icons/tfi'
import { MdDragIndicator } from 'react-icons/md'
import { uploadDispatch, useUploadContext } from '../../context/Upload/Context';
import { setFilesIntoStore } from '../../services/UploadService';
import ReactDragListView from 'react-drag-listview';

const MergeVideoList = ({ fileWithMeta, date, operationDate, index }) => {
  const {
		setMeregeVideo,
		mergeVideoStore,
    selectedFiles
	} = useUploadContext()
  const [patching, setPatching] = useState(false);

  const getDate = () => {
    return operationDate ||  Date(moment().valueOf().toString()) || fileWithMeta[0]?.meta.lastModifiedDate;
  }

  const updateDate = async (date) => {
    setPatching(true)
    try {
      const formattedDate = moment(date).format()
      patchMergeFiles(index, {
        operationDate: formattedDate,
      })
    } catch (e) {
      console.log('updateDate=======>>>>>>>',e)
    }
    setPatching(false);
  }

  const deMergeVideos =() => {
    const tempFiles = mergeVideoStore.filter((data)=>JSON.stringify(data)!==JSON.stringify({operationDate:getDate(), mergeVideos: fileWithMeta}))
    // console.log({tempFiles});
		uploadDispatch(setMeregeVideoStore(tempFiles))

    let tempeSelected = []
    selectedFiles.forEach((item2) => {
			const matchedItem = fileWithMeta.find((item1) => item1?.meta?.id === item2?.meta?.id);

			if (matchedItem) {
        tempeSelected = [...tempeSelected, matchedItem]
				item2.isHide = false;
			}
      // setMeregeVideo.push(fileWithMeta)
      uploadDispatch(setMeregeVideoFiles([...new Set([...setMeregeVideo, ...tempeSelected])]))
		});
		setFilesIntoStore(selectedFiles)
  }
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...fileWithMeta];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      // that.setState({ data });
      console.log(data, index);
      mergeVideoStore[index].mergeVideos = data;
      console.log(data,mergeVideoStore);
      // mergeVideoStore.push({operationDate: setMeregeVideo[0]?.meta?.lastModifiedDate, mergeVideos: setMeregeVideo})
		uploadDispatch(setMeregeVideoStore(mergeVideoStore))
    },
    nodeSelector: 'li',
    handleSelector: 'li'
  };

    return (

        <FileDetails>
          <FileLeftDiv>
            <Title>Merge video</Title>
            <ReactDragListView {...dragProps}>
            <ol style={{listStyle:'none', marginBlockStart:'0rem', paddingInlineStart: '10px', marginTop:'10px'}}>
              {fileWithMeta.map(({ meta }, index) => (
                  <FileName key={index}> <span> {index+1}. {meta.name}</span> <MdDragIndicator size={'20'} /></FileName>
              ))}
              </ol>
            </ReactDragListView>
          </FileLeftDiv>
          <FileRightDiv>
          {fileWithMeta?.meta?.error ?
          <Failure>
            File upload failed
            <IconImages type={'error'} style={{width: 28}}/>
          </Failure>
          : date ?
            <DateUpdater
              maxDate={new Date()}
              loading={patching} updateDate={updateDate} date={getDate()}/> : null
          }
          </FileRightDiv>
          <DeMergeIcon>
            <TfiSplitV className='trash' size={'30'} onClick={deMergeVideos} />
          </DeMergeIcon>
        </FileDetails>
    );
};

export default MergeVideoList;




const FileDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: thin solid #333333; */
  border-radius: 10px;
  font-size: 14px;
  width: 90%;
  padding-bottom: 10px;
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0px 4px 24px -4px rgba(227,224,227,1);
  -moz-box-shadow: 0px 4px 24px -4px rgba(227,224,227,1);
  box-shadow: 0px 4px 24px -4px rgba(227,224,227,1);
  @media (prefers-color-scheme: dark) {
    -webkit-box-shadow: 0px 0px 7px 2px rgba(66,63,66,1);
    -moz-box-shadow: 0px 0px 7px 2px rgba(66,63,66,1);
    box-shadow: 0px 0px 7px 2px rgba(66,63,66,1);
  }
  .trash {
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-right: 22px;
    margin-left: auto;
  }

  .video-player {
    margin-left: 22px;
  }

  .video-player, video, .video-player__thumbnail-container img, .video-player__thumbnail-container {
    height: 30px;
    width: 30px;
    border-radius: 7px;

    img {
      object-fit: cover;
    }
  }

  .video-player__icon-container {
    display: none;
  }

  margin-bottom: 16px;
`;

const FileName = styled.li`
  overflow-wrap: break-word;
  margin: 0px 5px 10px 0px;
  padding: 15px 10px;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  word-break: break-all;
  justify-content: space-between;
  border-radius: 10px;
  /* border-bottom: 1px solid gray; */
  -webkit-box-shadow: 0px 4px 24px -4px rgba(227,224,227,1);
  -moz-box-shadow: 0px 4px 24px -4px rgba(227,224,227,1);
  box-shadow: 0px 4px 24px -4px rgba(227,224,227,1);
  @media (prefers-color-scheme: dark) {
    -webkit-box-shadow: 0px 0px 7px 2px rgba(66,63,66,1);
    -moz-box-shadow: 0px 0px 7px 2px rgba(66,63,66,1);
    box-shadow: 0px 0px 7px 2px rgba(66,63,66,1);
  }
  span {
    width: 90%;
  }
`;

const Title = styled.span`
  overflow-wrap: break-word;
  margin-left: 15px;
  margin-top: 10px;
  font-family: FletchaMB, sans-serif;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #244F55;

`;
const FileLeftDiv = styled.div`
display: flex;
flex-direction: column;
width: 68%;
margin-left: 5%;
`;
const FileRightDiv = styled.div`
display: flex;
flex-direction: column;
`;

const DeMergeIcon = styled.div`
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
.trash {
    cursor: pointer;
    margin-right: 22px;
    margin-left: auto;
    color: #FF0000;
  }
`;
