import React from 'react'
import styled from 'styled-components';
import Logo from '../assets/logo.svg'

const Loader = ({size = 100}) => {

    return (
        <SpinningImage style={{width: size}} src={Logo} alt={'Loading...'}/>
    );
};

export default Loader;

const SpinningImage = styled.img`
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
