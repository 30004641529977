import React from 'react';
import styled from 'styled-components';
import {SplitView} from '../styles/common.js';
import {setUserAuthentication} from '../services/AuthService.js';
import Upload from '../components/Upload/Upload.js';
import GradientTall from '../assets/images/gradient-tall.png'
import Logo from '../assets/logo.svg'

const DashboardView = () => {
  return (
    <SplitView>
      <LeftPane>
        <StyledLogo src={Logo}/>
        <Gradient src={GradientTall}/>
      </LeftPane>
      <div style={{flex: 1, overflowY:'scroll', height:'100%'}}>
        <Upload/>
      </div>
      <SignOutButton onClick={() => setUserAuthentication(false)}>
        Sign Out
      </SignOutButton>
    </SplitView>
  );
};
export default DashboardView;

const StyledLogo = styled.img`
  width: 250px;
  position: absolute;
  z-index: 9999999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  @media only screen and (max-width: 1200px) {
    width: 200px;
  }
`

const Gradient = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`

const LeftPane = styled.div`
  flex: 0.70;
  position: relative;
  height: 100vh;

  @media only screen and (max-width: 999px) {
    display: none;
  }
`;

const SignOutButton = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
  width: 150px;

  text-align: right;

  font-family: FletchaMB, sans-serif;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #244F55;

  &:hover {
    color: #3EC9B1;
  }
`;
