import {userDispatch} from "./Context.js";

export const SET_USER_LOGGED_IN = 'SET_USER_LOGGED_IN';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_USER = 'SET_USER'
export const DIALOGUE = 'DIALOGUE'

export function setUserLoggedIn(isLoggedIn) {
  return {
    type: SET_USER_LOGGED_IN,
    payload: isLoggedIn,
  };
}

export function setUserToken(token) {
  return {
    type: SET_USER_TOKEN,
    payload: token,
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    payload: user,
  };
}

export function setAuthError(errorMessage) {
  return {
    type: SET_USER_TOKEN,
    payload: errorMessage,
  };
}

export function setDialogueMessage(message) {
  return {
    type: DIALOGUE,
    payload: message,
  };
}
