
import {setAuthError, setUserLoggedIn,} from '../context/User/Actions.js';
import {userDispatch} from '../context/User/Context.js';
import axios from "axios";
import moment from "moment";
import { auth } from '../index.js';
import 'firebase/auth'


export function setUserAuthentication(isLoggedIn) {
  try {
    if (!isLoggedIn) {
      localStorage.clear();
      window.webAuth.logout({
        returnTo: process.env.REACT_APP_HOST + '/sign-in',
      });
      auth.signOut()
    }
    const action = setUserLoggedIn(isLoggedIn);
    userDispatch(action);
  } catch (error) {
    console.log(error);
  }
}

export async function signIn(
  email,
  password,
  redirectToDashboard
) {

  await new Promise(resolve => {
    window.webAuth.login({
      email,
      password,
    }, (error, data) => {
      if (error) {
        setAuthError(error.description)
        redirectToDashboard(false, error.description)
      }
      auth.signInAnonymously().then((data)=>{
        console.log('firebase login successfully', data);
        return resolve();
      })


    })
  })
}

export async function signInLinkedin(redirectToDashboard) {
  await new Promise(resolve => {
    window.webAuth.authorize({
      connection: 'linkedin',
      prompt: 'login'
    }, (error, data) => {
      if (error) {
        setAuthError(error.description)
        redirectToDashboard(false, error.description)
      }
      resolve();
    })
  })
}

export async function signInApple(redirectToDashboard) {
  await new Promise(resolve => {
    window.webAuth.authorize({
      connection: 'apple',
      prompt: 'login'
    }, (error, data) => {
      alert(1)
      if (error) {
        setAuthError(error.description)
        redirectToDashboard(false, error.description)
      }
      resolve();
    })
  })
}

export async function signInDoximity(redirectToDashboard) {
  await new Promise(async resolve => {
    const data = await axios.get('https://auth.doximity.com/.well-known/oauth-authorization-server');
    const redirectUri = data?.data?.authorization_endpoint
    if (redirectUri) {
      const uri = `${process.env.REACT_APP_HOST}/sign-in`
      const clientId = process.env.REACT_APP_DOXIMITY_CLIENT_ID
      await axios.get(`https://auth.doximity.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${uri}&scope=openid&state=${moment().valueOf().toString()}&code_challenge=IoRbT8X2jffuTgXg8IVy5sAzjw-8C3a4RC1xGoaWCAY&code_challenge_method=S256`)
    }
  })
}

