import React, {createContext, useContext, useReducer} from 'react';
import {authReducer, initialState} from './Reducers.js';

export const UserContext = createContext({
  state: initialState,
  dispatch: () => null,
});

export const useAuthContext = () => {
  const {state} = useContext(UserContext);
  return state;
};

export let userDispatch;

export const UserContextWrapper = ({children}) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  userDispatch = dispatch;
  Object.freeze(userDispatch);

  return (
    <UserContext.Provider value={{state, dispatch}}>
      {children}
    </UserContext.Provider>
  );
};
