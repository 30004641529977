import React, {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {uploadDispatch, useUploadContext} from '../../context/Upload/Context';
import {IconImages} from "../../styles/icons.js";
import VideoPlayer from "simple-react-video-thumbnail-j";
import DateUpdater from "../DateUpdater.js";
import moment from 'moment'
import {patchSelectedFiles, patchUploadedFiles, setMeregeVideoFiles} from "../../context/Upload/Actions.js";
import {usePatchVideo} from "../../context/hooks/upload.hook.js";
import {
	setFilesIntoStore,
} from "../../services/UploadService"
import { db } from '../..';

const FileDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: thin solid #333333;  */
  border-radius: 10px;
  height: 67px;
  min-height: 67px;
  font-size: 14px;
  width: 90%;
  max-width: 680px;
  padding-left: 5px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0px 4px 24px -4px rgba(227,224,227,1);
  -moz-box-shadow: 0px 4px 24px -4px rgba(227,224,227,1);
  box-shadow: 0px 4px 24px -4px rgba(227,224,227,1);

  @media (prefers-color-scheme: dark) {
    -webkit-box-shadow: 0px 0px 7px 2px rgba(66,63,66,1);
    -moz-box-shadow: 0px 0px 7px 2px rgba(66,63,66,1);
    box-shadow: 0px 0px 7px 2px rgba(66,63,66,1);
  }

  .trash {
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-right: 22px;
    margin-left: auto;
  }

  .video-player {
    margin-left: 22px;
  }

  .video-player, video, .video-player__thumbnail-container img, .video-player__thumbnail-container {
    height: 30px;
    width: 30px;
    border-radius: 7px;

    img {
      object-fit: cover;
    }
  }

  .video-player__icon-container {
    display: none;
  }

  margin-bottom: 16px;
`;

const FileName = styled.span`
  overflow-wrap: break-word;
  margin-left: 22px;
  width: 60%;
`;

const Preview = memo(({meta, fileWithMeta, date, index, checkBoxStatus, isHide, filteredVideoIds}) => {
    const [fileData, setFileData] = useState({
      localUrl: '',
      thumb: ''
    })
    const {name} = meta;
    const {filesUploadProgress, submissionFinished} = useUploadContext();
    const [patching, setPatching] = useState(false);
    const patch = usePatchVideo()
    const [isSelected, setIsSelected] = useState(false);
    const videoPlayerRef = useCallback(ref => {

    }, [])

    const {
      setMeregeVideo,
      selectedFiles
    } = useUploadContext()


    const updateDate = async (date) => {
      setPatching(true)
      try {

        const formattedDate = moment(date).format()
        patchSelectedFiles(index, {
          operationDate: formattedDate,
        })

        await patch.mutateAsync({
          operationDate: moment(date).valueOf().toString(),
          videoId: fileWithMeta?.filenameData.filename,
        })


      } catch (e) {
        console.log(e)
      }
      setPatching(false);
    }

    useEffect(() => {
      if (fileWithMeta) {
        const u = (window.URL || window.webkitURL);
        if (u) {
          setFileData({
            thumb: '',
            localUrl: u.createObjectURL(fileWithMeta.file)
          })
        }
      }
    }, [fileWithMeta])

    const remove = async() => {
      console.log({selectedFiles: selectedFiles?.length});
      const afterRemoveData = selectedFiles.filter((item) => item.filenameData.filename !== fileWithMeta.filenameData.filename);
      console.log({afterRemoveData: afterRemoveData?.length});
      setFilesIntoStore(afterRemoveData)
      fileWithMeta.remove();
      if(isSelected) {
        const filterData = setMeregeVideo.filter((obj) => obj?.meta?.id !== fileWithMeta?.meta?.id);
        uploadDispatch(setMeregeVideoFiles(filterData))
      }

      // delete date from collection
      // console.log('logss =====>>>>>', fileWithMeta?.filenameData?.filename);
      db.collection('videosDates').doc(fileWithMeta?.filenameData?.filename).delete()
    };

    const getDate = () => {
      return fileWithMeta?.operationDate || Date(moment().valueOf().toString()) || fileWithMeta?.file?.lastModifiedDate;
    }

    const handleSelect = (fileWithMeta) => {
      setIsSelected(!isSelected);
      if(!isSelected) {
        /* const obj = {
          videoId : fileWithMeta.uid
        } */
        setMeregeVideo.push(fileWithMeta)
        uploadDispatch(setMeregeVideoFiles([...new Set(setMeregeVideo)]))
      }else{
        const filterData = setMeregeVideo.filter((obj) => obj?.meta?.id !== fileWithMeta?.meta?.id);
        // console.log([...new Set(filterData)]);
        uploadDispatch(setMeregeVideoFiles(filterData))
      }
    }

    useEffect(() => {
      // console.log(filteredVideoIds, 'fileWithMeta', fileWithMeta);
      filteredVideoIds?.length ? setIsSelected(filteredVideoIds.includes(fileWithMeta?.filenameData?.filename)) : null
    },[filteredVideoIds])
console.log(isHide);
    return (
      isHide ? null : <FileDetails>
        {checkBoxStatus ? <input style={{}} type="checkbox" checked={isSelected} onChange={() => handleSelect(fileWithMeta)} /> : null}
        {fileData.localUrl && <VideoPlayer
          videoUrl={fileData.localUrl}
          snapshotAt={3}
          ref={videoPlayerRef}
        />}
        <FileName>{name}</FileName>
        {meta.error ?
          <Failure>
            File upload failed
            <IconImages type={'error'} style={{width: 28}}/>
          </Failure>
          : date ?
            <DateUpdater
              maxDate={new Date()}
              loading={patching} updateDate={updateDate} date={getDate()}/> : null
        }
        {!submissionFinished && (
          <IconImages className="trash" onClick={remove} type={'trash'}/>
        )}
      </FileDetails>
    );
});

export default Preview;

const Failure = styled.div`
  background-color: rgba(255, 255, 255, 0.08);
  max-width: 165px;
  width: 150px;
  height: 37px;
  border-radius: 8px;
  margin-left: auto;
  margin-right: 20px;
  color: #B6B6B6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 10px;
  font-family: Inter, sans-serif;
`
