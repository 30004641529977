import {SET_AUTH_ERROR, SET_USER, SET_USER_LOGGED_IN, SET_USER_TOKEN, DIALOGUE} from './Actions';

export const initialState = {
  isLoggedIn: !!localStorage.getItem('token'),
  token: '',
  errorMessage: '',
  redirectionPermission: false,
  user: null,
  dialogueMessage: ''
};

export function authReducer(state, action) {
  switch (action.type) {
    case SET_USER_LOGGED_IN: {
      const isLoggedIn = action.payload;
      return {...state, isLoggedIn};
    }
    case SET_USER_TOKEN: {
      const token = action.payload;
      localStorage.setItem('token', token);
      return {...state, token};
    }
    case SET_AUTH_ERROR: {
      const errorMessage = action.payload;
      return {...state, errorMessage};
    }
    case DIALOGUE: {
      console.log(4, action.payload)
      return {
        ...state,
        dialogueMessage: action.payload
      }
    }
    case SET_USER: {
      return {
        ...state,
        user: action.payload
      }
    }
    default:
      return state;
  }
}
